import { Component } from '@angular/core';
import { AbstractTreeIconComponent } from './abstract-tree-icon.component';

@Component({
  selector: 'app-icon-section',
  styleUrls: ['./icons.scss', './icons-tree.scss'],
  standalone: true,
  template: ` <svg viewBox="0 0 60 60" [attr.width]="size" [attr.height]="size" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M 37.3,22.9 H 33 c -0.4,0 -0.7,0.3 -0.7,0.7 V 28 c 0,0.4 0.3,0.7 0.7,0.7 h 4.4 c 0.4,0 0.7,-0.3 0.7,-0.7 V 23.6 C 38,23.2 37.7,22.9 37.3,22.9 Z m -0.6,4.3 h -3 v -3 h 3 z m -9.6,-4.3 h -4.4 c -0.4,0 -0.7,0.3 -0.7,0.7 V 28 c 0,0.4 0.3,0.7 0.7,0.7 h 4.4 c 0.4,0 0.7,-0.3 0.7,-0.7 v -4.4 c 0,-0.4 -0.3,-0.7 -0.7,-0.7 z m -0.7,4.3 h -3 v -3 h 3 z M 37.3,13.7 H 33 c -0.4,0 -0.7,0.3 -0.7,0.7 v 4.4 c 0,0.4 0.3,0.7 0.7,0.7 h 4.4 c 0.4,0 0.7,-0.3 0.7,-0.7 V 14.4 C 38,14 37.7,13.7 37.3,13.7 Z m -0.6,4.4 h -3 v -3 h 3 z M 27.1,13.7 H 22.7 C 22.3,13.7 22,14 22,14.4 v 4.4 c 0,0.4 0.3,0.7 0.7,0.7 h 4.4 c 0.4,0 0.7,-0.3 0.7,-0.7 v -4.4 c 0,-0.4 -0.3,-0.7 -0.7,-0.7 z m -0.7,4.4 h -3 v -3 h 3 z m -7.7,-6.8 h 22.7 v 17.1 h 2.8 V 9.9 C 44.2,9.1 43.6,8.5 42.8,8.5 H 17.3 c -0.8,0 -1.4,0.6 -1.4,1.4 v 18.4 h 2.8 z M 51.2,31 h -1.4 c -0.4,0 -0.7,-0.3 -0.7,-0.7 0,-0.4 0.3,-0.7 0.7,-0.7 h 1.4 c 0.4,0 0.7,0.3 0.7,0.7 0,0.4 -0.4,0.7 -0.7,0.7 z m -4.1,0 h -2.6 c -0.4,0 -0.7,-0.3 -0.7,-0.7 0,-0.4 0.3,-0.7 0.7,-0.7 h 2.6 c 0.4,0 0.7,0.3 0.7,0.7 0,0.4 -0.3,0.7 -0.7,0.7 z m -5.2,0 h -2.6 c -0.4,0 -0.7,-0.3 -0.7,-0.7 0,-0.4 0.3,-0.7 0.7,-0.7 h 2.6 c 0.4,0 0.7,0.3 0.7,0.7 0,0.4 -0.3,0.7 -0.7,0.7 z m -5.3,0 H 34 c -0.4,0 -0.7,-0.3 -0.7,-0.7 0,-0.4 0.3,-0.7 0.7,-0.7 h 2.6 c 0.4,0 0.7,0.3 0.7,0.7 0,0.4 -0.3,0.7 -0.7,0.7 z m -5.3,0 H 28.7 C 28.3,31 28,30.7 28,30.3 c 0,-0.4 0.3,-0.7 0.7,-0.7 h 2.6 c 0.4,0 0.7,0.3 0.7,0.7 0,0.4 -0.3,0.7 -0.7,0.7 z m -5.2,0 h -2.6 c -0.4,0 -0.7,-0.3 -0.7,-0.7 0,-0.4 0.3,-0.7 0.7,-0.7 h 2.6 c 0.4,0 0.7,0.3 0.7,0.7 0,0.4 -0.3,0.7 -0.7,0.7 z m -5.3,0 h -2.6 c -0.4,0 -0.7,-0.3 -0.7,-0.7 0,-0.4 0.3,-0.7 0.7,-0.7 h 2.6 c 0.4,0 0.7,0.3 0.7,0.7 0,0.4 -0.3,0.7 -0.7,0.7 z m -5.3,0 h -2.6 c -0.4,0 -0.7,-0.3 -0.7,-0.7 0,-0.4 0.3,-0.7 0.7,-0.7 h 2.6 c 0.4,0 0.7,0.3 0.7,0.7 0,0.4 -0.3,0.7 -0.7,0.7 z m -5.2,0 H 8.9 c -0.4,0 -0.7,-0.3 -0.7,-0.7 0,-0.4 0.3,-0.7 0.7,-0.7 h 1.4 c 0.4,0 0.7,0.3 0.7,0.7 0,0.4 -0.3,0.7 -0.7,0.7 z" />
      <path
        opacity="0.3"
        d="M 37.3,32 H 33 c -0.4,0 -0.7,0.3 -0.7,0.7 v 4.4 c 0,0.4 0.3,0.7 0.7,0.7 h 4.4 c 0.4,0 0.7,-0.3 0.7,-0.7 V 32.7 C 38,32.3 37.7,32 37.3,32 Z m -0.6,4.4 h -3 v -3 h 3 z M 27.1,32 H 22.7 C 22.3,32 22,32.3 22,32.7 v 4.4 c 0,0.4 0.3,0.7 0.7,0.7 h 4.4 c 0.4,0 0.7,-0.3 0.7,-0.7 V 32.7 C 27.8,32.3 27.5,32 27.1,32 Z m -0.7,4.4 h -3 v -3 h 3 z M 44.2,48.7 V 32.2 h -2.8 v 16.5 h -8 v -8 C 33.4,40.3 33.1,40 32.7,40 h -5.4 c -0.4,0 -0.7,0.3 -0.7,0.7 v 8 h -8 V 32.2 h -2.8 v 16.5 h -7 v 2.8 h 42.3 v -2.8 z m -12.2,0 h -4 v -7.3 h 4 z" />
    </g>
  </svg>`,
})
export class IconSectionComponent extends AbstractTreeIconComponent {}
