import { Component, Input } from '@angular/core';
import { IconAssetGroupComponent } from '../../icons/icon-asset-group.component';
import { IconAssetComponent } from '../../icons/icon-asset.component';
import { IconLevelComponent } from '../../icons/icon-level.component';
import { IconMeterColdComponent } from '../../icons/icon-meter-cold.component';
import { IconMeterGasComponent } from '../../icons/icon-meter-gas.component';
import { IconMeterGroupComponent } from '../../icons/icon-meter-group.component';
import { IconMeterHeatComponent } from '../../icons/icon-meter-heat.component';
import { IconMeterVirtualComponent } from '../../icons/icon-meter-virtual.component';
import { IconMeterComponent } from '../../icons/icon-meter.component';
import { IconResourceComponent } from '../../icons/icon-resource.component';
import { IconRoomComponent } from '../../icons/icon-room.component';
import { IconSectionComponent } from '../../icons/icon-section.component';
import { IconZoneComponent } from '../../icons/icon-zone.component';

@Component({
  selector: 'app-asset-tree-icon',
  imports: [
    IconAssetGroupComponent,
    IconAssetComponent,
    IconSectionComponent,
    IconLevelComponent,
    IconZoneComponent,
    IconRoomComponent,
    IconResourceComponent,
    IconMeterGroupComponent,
    IconMeterVirtualComponent,
    IconMeterGasComponent,
    IconMeterHeatComponent,
    IconMeterColdComponent,
    IconMeterComponent,
  ],
  styles: `
    .app-icon {
      width: 100%;
      height: 100%;
    }
  `,
  template: `
    <!-- prettier-ignore -->
    @switch (type) {
      @case ('root')                    { <app-icon-asset-group></app-icon-asset-group> }
      @case ('assetGroup')              { <app-icon-asset-group></app-icon-asset-group> }
      @case ('asset')                   { <app-icon-asset></app-icon-asset> }
      @case ('section')                 { <app-icon-section></app-icon-section> }
      @case ('level')                   { <app-icon-level></app-icon-level> }
      @case ('zone')                    { <app-icon-zone></app-icon-zone> }
      @case ('room')                    { <app-icon-room></app-icon-room> }
      @case ('resource')                { <app-icon-resource></app-icon-resource> }
      @case ('assetMeteringPointGroup') { <app-icon-meter-group></app-icon-meter-group> }
      @case ('virtualMeteringPoint')    { <app-icon-meter-virtual></app-icon-meter-virtual> }
      @case ('meteringPoint') {
        @switch (variant) {
          @case (4)    { <app-icon-meter-gas></app-icon-meter-gas> }
          @case (5)    { <app-icon-meter-heat></app-icon-meter-heat> }
          @case (6)    { <app-icon-meter-cold></app-icon-meter-cold> }
          @case (null) { <app-icon-meter-virtual></app-icon-meter-virtual> }
          @default     { <app-icon-meter></app-icon-meter> }
        }
      }
      @default { <div class="app-icon">&nbsp;</div> }
    }
  `,
})
export class AssetTreeIconComponent {
  @Input() type!: string;
  @Input() variant?: number;

  // Meter variant types
  // 1	Timesmålt
  // 2	Profilmålt
  // 3	Standmålt
  // 4	Gass
  // 5	Fjernvarme
  // 6	Kjøling
  // 7	Strøm u/målepunkt
  // 8	Annet
  // 9	Undermåler - delt ID
}
