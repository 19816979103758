import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { CommonModule } from '@angular/common';
import { Component, computed, inject, model, signal } from '@angular/core';
import { MatChip } from '@angular/material/chips';
import { RouterModule } from '@angular/router';
import { FeatureFlagService } from '@logic-suite/shared/feature-flag/feature-flag.service';
import { TranslateModule } from '@ngx-translate/core';
import { AssetNodeHelpers } from './asset-node.helpers';
import { AssetTreeIconComponent } from './asset-tree-icon.component';
import { AssetTreeService } from './asset-tree.service';
import { AssetNode } from './node.model';
import { EnergyBlocksService } from '@suite/energy-blocks-data-access';

export interface AssetNodeParams {
  entryType?: (data: any) => string;
  entryID?: (data: any) => string;
  entryName?: (data: any) => string;
  routeTo?: (data: any) => AssetNodeRouteParams;
}

export interface AssetNodeRouteParams {
  route: string[];
  queryParams?: Record<string, string>;
}

@Component({
  selector: 'app-asset-node',
  imports: [CommonModule, AssetTreeIconComponent, RouterModule, TranslateModule, MatChip],
  styleUrl: 'asset-tree-node.component.scss',
  templateUrl: 'asset-tree-node.component.html',
})
export class AssetTreeNodeComponent implements ICellRendererAngularComp {
  private readonly assetTreeService = inject(AssetTreeService);
  readonly energyBlocksService = inject(EnergyBlocksService);
  readonly featureFlagService = inject(FeatureFlagService);
  readonly assetNodeHelpers = inject(AssetNodeHelpers);

  node = model<AssetNode>();
  params = signal<ICellRendererParams & AssetNodeParams>({} as ICellRendererParams & AssetNodeParams);
  isFooter = computed(() => this.params().node?.footer);
  isLink = computed(() => 'routeTo' in this.params() && this.routeTo().route?.length > 0);
  routeTo = computed(() => this.params().routeTo!(this.params().data));

  activeEnergyBlocks = computed(() =>
    this.node() == null ? ['0'] : this.assetNodeHelpers.getActiveEnergyBlockIds(this.node()!),
  );

  agInit(params: ICellRendererParams & AssetNodeParams): void {
    this.refresh(params);
  }

  refresh(params: ICellRendererParams & AssetNodeParams): boolean {
    if ('innerCellRendererParams' in params) {
      this.params.set({ ...params, ...(params.innerCellRendererParams as AssetNodeParams) });
    } else {
      this.params.set(params);
    }

    if (!this.isFooter() && params.data != null) {
      const row = params.data;
      const entryType = 'entryType' in this.params() ? this.params().entryType!(row) : row.entryType;
      const entryID = 'entryID' in this.params() ? this.params().entryID!(row) : row.entryID;
      const name = 'entryName' in this.params() ? this.params().entryName!(row) : undefined;
      const node = this.assetTreeService.findNode(entryType, entryID);
      if (node && name != null) {
        node.name = name;
      }

      this.node.set(
        node
          ? node
          : ({
              id: entryID,
              type: entryType,
              name: name,
              meteringPointTypeID: row.meteringPointTypeID,
            } as AssetNode),
      );
    }
    return true;
  }
}
