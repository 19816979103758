import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { computed, inject, Injectable, linkedSignal } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { Logger } from '@logic-suite/shared/logger/logger.service';
import { EMPTY, map, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomerService } from '../../../../../../../apps/suite/src/app/shared/access';
import { EnergyBlock, EnergyBlockEntity, NS3031_ENERGY_BLOCKS } from './energy-block.model';

/**
 * Service for managing the energy blocks resource.
 *
 * See also {@link EnergyBlocksAssignmentService}
 */
@Injectable({ providedIn: 'root' })
export class EnergyBlocksService {
  private http = inject(HttpClient);
  private logger = inject(Logger);
  private customerService = inject(CustomerService);
  private readonly API_BASE_URL = '/api/energy/EnergyBlocks';

  energyBlocksResource = rxResource({
    request: () => this.customerService.selectedCustomer(),
    loader: () => this.fetchEnergyBlocks(),
  });

  energyBlocks = linkedSignal<EnergyBlock[] | undefined, EnergyBlock[]>({
    source: this.energyBlocksResource.value,
    computation: (energyBlocks: EnergyBlock[] | undefined) => energyBlocks ?? [],
  });

  /**
   * Map of energy blocks by id to facilitate quick lookups by id.
   */
  energyBlocksMap = computed(() => {
    const map = new Map<string, EnergyBlock>();
    for (const block of this.energyBlocks()) {
      map.set(block.id, block);
    }
    return map;
  });

  /**
   * Sorts energy blocks so they appear in the same order as in the NS3031 standard.
   */
  private sortByNS3031 = (energyBlocks: EnergyBlock[]): EnergyBlock[] =>
    energyBlocks.sort((a, b) => {
      const aIndex = NS3031_ENERGY_BLOCKS.indexOf(a.id);
      const bIndex = NS3031_ENERGY_BLOCKS.indexOf(b.id);
      return aIndex - bIndex;
    });

  /**
   * Fetches and sorts energy blocks from the API.
   */
  private fetchEnergyBlocks(): Observable<EnergyBlock[]> {
    return this.http.get<EnergyBlockEntity[]>(this.apiUrl()).pipe(
      map(EnergyBlock.fromEntities),
      map(this.sortByNS3031),
      catchError((err: HttpErrorResponse) => {
        this.logger.error(`Could not fetch energy blocks.`, 'EnergyBlocksService', err);
        return EMPTY;
      }),
    );
  }

  private apiUrl(path: string = ''): string {
    return `${this.API_BASE_URL}${path}`;
  }
}
