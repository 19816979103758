import { Injectable } from '@angular/core';
import { AssetNode } from './node.model';

@Injectable({ providedIn: 'root' })
export class AssetNodeHelpers {
  /**
   * Returns a list of the energy block ids that are active now.
   *
   * If an energy block's interval contains the current date, it
   * is considered to be active.
   */
  getActiveEnergyBlockIds(node: AssetNode): string[] {
    if (!node.energyBlockIntervals || node.energyBlockIntervals.length === 0) {
      return [];
    }

    const now = Date.now();
    const activeIntervals = node.energyBlockIntervals.filter(
      (interval) => interval.validFrom <= now && now <= interval.validTo,
    );

    // Use Set to prevent duplicates.
    const activeEnergyBlockIds = new Set<string>();

    activeIntervals.forEach((interval) => {
      interval.energyBlockFactors.forEach((factor) => {
        if (factor.factor > 0) {
          activeEnergyBlockIds.add(factor.energyBlock.id);
        }
      });
    });

    return Array.from(activeEnergyBlockIds);
  }
}
