import { HttpClient } from '@angular/common/http';
import { computed, inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of, share, shareReplay, switchMap, tap } from 'rxjs';

import { RequestCache } from '@logic-suite/shared/cache';

import { rxResource, toObservable } from '@angular/core/rxjs-interop';
import { ApplicationStorageService } from '@logic-suite/shared/storage';
import { HierarchyNode } from '@suite/hierarchy-data-access';
import { UserPrivilege } from 'libs/suite/shared/data-access/user/src/lib/privilege.model';
import { Role } from './access.model';
import { CustomerService } from './customer.service';
import { User } from './user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly http = inject(HttpClient);
  private readonly cache = inject(RequestCache);
  private readonly storage = inject(ApplicationStorageService);
  private readonly customerService = inject(CustomerService);

  // Sources of change.
  user = rxResource({
    request: () => ({
      customerID: this.customerService.selectedCustomer()?.customerID,
    }),
    loader: () =>
      this.http.get<User>(`/api/access/Users/Me`).pipe(
        tap((u) => this.storage.setItem('cache.access.user', u)),
        catchError(() => of({} as User)),
      ),
  });
  currentUser = computed<User>(() => {
    // Get user from cache if not loaded
    let user = this.user.value();
    if (!user) user = this.storage.getItem('cache.access.user', {});
    return user as User;
  });
  user$ = toObservable<User>(this.currentUser) as Observable<User>;

  private triggerUserReload$ = new BehaviorSubject(0);

  findPrivilegeMatching(
    featureName: string,
    node: Partial<HierarchyNode>,
    level?: string,
  ): { match: UserPrivilege[]; access: boolean } | undefined {
    let privileges = (this.currentUser()?.privileges ?? []).filter(
      (p) => p.name.toLowerCase() === featureName.toLowerCase(),
    );
    if (node) {
      privileges = privileges.filter((p) => p.resourceId === node.id && p.resourceType === node.type);
    }
    if (privileges.length > 0 && level) {
      const access = privileges.every((p) => {
        // Privilege is set, return if user has the appropriate level of access
        return level.split('').every((l) => {
          // prettier-ignore
          switch (l) {
            case 'C':
              return p.create;
              break;
            case 'R':
              return p.read;
              break;
            case 'U':
              return p.update;
              break;
            case 'D':
              return p.delete;
              break;
          }
          return false;
        });
      });
      return { match: privileges, access };
    }
    // No level given. Just return if the privilege exists
    if (privileges.length === 0) return undefined;
    return { match: privileges, access: true };
  }

  getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>('/api/access/User/Role').pipe(shareReplay(1));
  }

  getUsers(): Observable<User[]> {
    return this.triggerUserReload$.asObservable().pipe(
      switchMap(() => this.http.get<User[]>(`/api/access/User/${this.customerService.get().customerID}`)),
      share(),
    );
  }

  saveUser(user: User): Observable<User> {
    return this.http.post<User>(`/api/access/User/${this.customerService.get().customerID}`, user).pipe(
      tap(() => {
        this.cache.invalidate('/api/access/User');
        this.triggerUserReload$.next(this.triggerUserReload$.value + 1);
      }),
    );
  }

  removeUser(user: User): Observable<any> {
    return this.http.delete(`/api/access/User/${this.customerService.get().customerID}/${user.userID}`).pipe(
      tap(() => {
        this.cache.invalidate('/api/access/User');
        this.triggerUserReload$.next(this.triggerUserReload$.value + 1);
      }),
    );
  }
}
