import { Component } from '@angular/core';
import { AbstractTreeIconComponent } from './abstract-tree-icon.component';

@Component({
  selector: 'app-icon-asset-group',
  styleUrls: ['./icons.scss', './icons-tree.scss'],
  standalone: true,
  template: ` <svg viewBox="0 0 60 60" [attr.width]="size" [attr.height]="size" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M54,46.6v-28c0-0.7-0.6-1.2-1.2-1.2h-8.3v2.5h7v26.8h-8.7V12.2c0-0.7-0.6-1.2-1.2-1.2H18.9c-0.7,0-1.2,0.6-1.2,1.2v34.4H8.5V19.8h7v-2.5H7.3c-0.7,0-1.2,0.6-1.2,1.2v28H4.4V49h51.3v-2.5H54z M32,46.1h-3.6V40H32V46.1z M20.1,13.4h20.3v32.7h-7.1v-6.7c0-0.3-0.3-0.6-0.6-0.6h-4.8c-0.3,0-0.6,0.3-0.6,0.6v6.7h-7.1V13.4z" />
      <path
        d="M23.7,20.5h3.9c0.3,0,0.6-0.3,0.6-0.6v-3.9c0-0.3-0.3-0.6-0.6-0.6h-3.9c-0.3,0-0.6,0.3-0.6,0.6v3.9C23.1,20.2,23.4,20.5,23.7,20.5z M24.3,16.6H27v2.7h-2.7V16.6z" />
      <path
        d="M32.8,20.5h3.9c0.3,0,0.6-0.3,0.6-0.6v-3.9c0-0.3-0.3-0.6-0.6-0.6h-3.9c-0.3,0-0.6,0.3-0.6,0.6v3.9C32.2,20.2,32.5,20.5,32.8,20.5z M33.5,16.6h2.7v2.7h-2.7V16.6z" />
      <path
        d="M23.7,28.6h3.9c0.3,0,0.6-0.3,0.6-0.6v-3.9c0-0.3-0.3-0.6-0.6-0.6h-3.9c-0.3,0-0.6,0.3-0.6,0.6V28C23.1,28.4,23.4,28.6,23.7,28.6z M24.3,24.7H27v2.7h-2.7V24.7z" />
      <path
        d="M32.8,28.6h3.9c0.3,0,0.6-0.3,0.6-0.6v-3.9c0-0.3-0.3-0.6-0.6-0.6h-3.9c-0.3,0-0.6,0.3-0.6,0.6V28C32.2,28.4,32.5,28.6,32.8,28.6z M33.5,24.7h2.7v2.7h-2.7V24.7z" />
      <path
        d="M27.6,36.8c0.3,0,0.6-0.3,0.6-0.6v-3.9c0-0.3-0.3-0.6-0.6-0.6h-3.9c-0.3,0-0.6,0.3-0.6,0.6v3.9c0,0.3,0.3,0.6,0.6,0.6H27.6z M24.3,32.9H27v2.7h-2.7V32.9z" />
      <path
        d="M32.8,36.8h3.9c0.3,0,0.6-0.3,0.6-0.6v-3.9c0-0.3-0.3-0.6-0.6-0.6h-3.9c-0.3,0-0.6,0.3-0.6,0.6v3.9C32.2,36.6,32.5,36.8,32.8,36.8z M33.5,32.9h2.7v2.7h-2.7V32.9z" />
      <path
        d="M45.1,28.6H49c0.3,0,0.6-0.3,0.6-0.6v-3.9c0-0.3-0.3-0.6-0.6-0.6h-3.9c-0.3,0-0.6,0.3-0.6,0.6V28C44.5,28.4,44.8,28.6,45.1,28.6z M45.7,24.7h2.7v2.7h-2.7V24.7z" />
      <path
        d="M44.5,36.2c0,0.3,0.3,0.6,0.6,0.6H49c0.3,0,0.6-0.3,0.6-0.6v-3.9c0-0.3-0.3-0.6-0.6-0.6h-3.9c-0.3,0-0.6,0.3-0.6,0.6V36.2zM45.7,32.9h2.7v2.7h-2.7V32.9z" />
      <path
        d="M15.5,24.1c0-0.3-0.3-0.6-0.6-0.6H11c-0.3,0-0.6,0.3-0.6,0.6V28c0,0.3,0.3,0.6,0.6,0.6h3.9c0.3,0,0.6-0.3,0.6-0.6V24.1zM14.3,27.4h-2.7v-2.7h2.7V27.4z" />
      <path
        d="M14.9,31.7H11c-0.3,0-0.6,0.3-0.6,0.6v3.9c0,0.3,0.3,0.6,0.6,0.6h3.9c0.3,0,0.6-0.3,0.6-0.6v-3.9C15.5,32,15.3,31.7,14.9,31.7z M14.3,35.6h-2.7v-2.7h2.7V35.6z" />
    </g>
  </svg>`,
})
export class IconAssetGroupComponent extends AbstractTreeIconComponent {}
