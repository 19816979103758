/**
 * The ID used for energy block when a device has none.
 *
 * Used for the rest of the consumption, i.e. the consumption that is not
 * attributed to any specific energy block.
 */
export const DEFAULT_ENERGY_BLOCK_ID = '00000000-0000-0000-0000-000000000000' as const;

/**
 * The order of energy blocks as they appear in the NS3031 standard.
 *
 * The order is important for the presentation of the data.
 * Default energy block ID (rest) should always be last.
 */
export const NS3031_ENERGY_BLOCKS = [
  '6274a2b6-7f6e-4d9e-8e6b-6b6d8f7f9a2e', // Room heating
  '2d7b6c9a-4e8f-40c7-82ea-1d9a9b0f6e7a', // Ventilation heating
  '92a4c3f8-9e8c-41a7-b9a3-5b8e7c9a2f4d', // Hot water
  '6e7d9f2a-b8e6-45a9-a2f7-3c8b9d4e6a1f', // Room cooling
  'f2a7b9e3-8c6d-47a2-9e1b-7b6d4f8a9c2e', // Ventilation cooling
  '3b6e7c9a-2f8e-4d1a-b7a9-6e4f9b8d2c7a', // Fans
  'a9e6b7d2-4c8f-4a1b-3e7d-9f2a6b8c9d4e', // Pumps
  'e7a4d9b6-2c8f-4b1e-a3d7-9a6f2e7b8c9a', // Lighting
  '1f6a9b8d-3e2c-4a7e-b9d2-8c6f4a7b9e2a', // Electrical equipment
  DEFAULT_ENERGY_BLOCK_ID, // rest
];

export const NS3031_ENERGY_BLOCK_NAMES = {
  RoomHeating: '6274a2b6-7f6e-4d9e-8e6b-6b6d8f7f9a2e',
  VentilationHeating: '2d7b6c9a-4e8f-40c7-82ea-1d9a9b0f6e7a',
  HotWater: '92a4c3f8-9e8c-41a7-b9a3-5b8e7c9a2f4d',
  RoomCooling: '6e7d9f2a-b8e6-45a9-a2f7-3c8b9d4e6a1f',
  VentilationCooling: 'f2a7b9e3-8c6d-47a2-9e1b-7b6d4f8a9c2e',
  Fans: '3b6e7c9a-2f8e-4d1a-b7a9-6e4f9b8d2c7a',
  Pumps: 'a9e6b7d2-4c8f-4a1b-3e7d-9f2a6b8c9d4e',
  Lighting: 'e7a4d9b6-2c8f-4b1e-a3d7-9a6f2e7b8c9a',
  ElectricalEquipment: '1f6a9b8d-3e2c-4a7e-b9d2-8c6f4a7b9e2a',
} as const;
export type NS3031_ENERGY_BLOCK_NAMES = (typeof NS3031_ENERGY_BLOCK_NAMES)[keyof typeof NS3031_ENERGY_BLOCK_NAMES];

/**
 * A "tag" that can be added to metering points.
 *
 * Energy blocks are used to categorize energy consumption.
 * There are 9 default energy blocks defined in the NS3031 standard.
 * The interface is designed to allow for custom energy blocks as well,
 * however, this is not currently supported.
 */
export class EnergyBlock implements EnergyBlockInterface {
  readonly id: string;
  readonly name: string;
  readonly description: string;
  readonly color: string;
  readonly isDefault: boolean;

  private constructor(entity: EnergyBlockEntity) {
    this.id = entity.id;
    this.name = entity.name;
    this.description = entity.description;
    this.color = entity.color;
    this.isDefault = entity.isDefault;
  }

  static fromEntity(entity: EnergyBlockEntity): EnergyBlock {
    return new EnergyBlock(entity);
  }

  static fromEntities(entities: EnergyBlockEntity[]): EnergyBlock[] {
    return entities.map(EnergyBlock.fromEntity);
  }
}

export interface EnergyBlockInterface extends EnergyBlockEntity {}

export interface EnergyBlockEntity {
  /**
   * UUID representing the energy block.
   *
   * See {@link NS3031_ENERGY_BLOCKS} for the standard energy blocks.
   */
  id: string;

  /**
   * Human-readable name of the energy block.
   */
  name: string;
  /**
   * Human-readable description of the energy block.
   */
  description: string;

  /**
   * Hex string representing the color of the energy block.
   */
  color: string;

  /**
   * True if the energy block is one of the standard NS3031 energy blocks.
   */
  isDefault: boolean;
}
