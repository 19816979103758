import { Component } from '@angular/core';
import { AbstractTreeIconComponent } from './abstract-tree-icon.component';

@Component({
  selector: 'app-icon-resource',
  styleUrls: ['./icons.scss', './icons-tree.scss'],
  standalone: true,
  template: ` <svg viewBox="0 0 60 60" [attr.width]="size" [attr.height]="size" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M32.8,24.3h-2.5c-0.9,0-1.7,0.8-1.7,1.7c0,0.9,0.8,1.7,1.7,1.7h2.5c0.9,0,1.7-0.8,1.7-1.7C34.5,25.1,33.7,24.3,32.8,24.3zM32.8,26.6h-2.5c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6h2.5c0.3,0,0.6,0.3,0.6,0.6C33.4,26.3,33.1,26.6,32.8,26.6z" />
      <path
        d="M52,41.8h-3.7c0-0.1,0.1-0.2,0.1-0.3V10.5c0-0.6-0.5-1.1-1.1-1.1H26.1c-0.6,0-1.1,0.5-1.1,1.1v18.9H8.7c-0.6,0-1.1,0.5-1.1,1.1v5.2c0,0.6,0.5,1.1,1.1,1.1h2.2v5.1H7.8v1.1h3.2v5.2c0,0.6,0.5,1.1,1.1,1.1h9.1c0.6,0,1.1-0.5,1.1-1.1v-5.2h15.7v6.2h2.2v-6.2H52V41.8z M27.2,36.8h10.7v3.7H27.2V36.8z M27.2,11.6h18.9v28.9h-6v-3.7H42c0.6,0,1.1-0.5,1.1-1.1v-5.2c0-0.6-0.5-1.1-1.1-1.1H27.2V11.6z M9.9,31.6h31v3H26.2c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0H9.9V31.6z M20,36.8v2.3h-6.8v-2.3H20zM13.1,40.2H20v3.1h-6.8V40.2z M13.1,47.1v-2.7H20v2.7H13.1z M22.2,36.8H25v4.8c0,0.1,0,0.2,0.1,0.3h-2.9V36.8z" />
    </g>
  </svg>`,
})
export class IconResourceComponent extends AbstractTreeIconComponent {}
