import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CacheModule } from '@logic-suite/shared/cache/cache.module';

import { AssetTreeIconComponent } from './asset-tree-icon.component';
import { AssetTreeNodeComponent } from './asset-tree-node.component';
import { AssetTreeComponent } from './asset-tree.component';

@NgModule({
  declarations: [AssetTreeComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,

    MatButtonModule,
    MatTreeModule,
    MatIconModule,
    MatProgressBarModule,
    MatSnackBarModule,
    TranslateModule,

    CacheModule,
    AssetTreeIconComponent,
    AssetTreeNodeComponent,
  ],
  exports: [AssetTreeComponent, AssetTreeIconComponent, AssetTreeNodeComponent],
})
export class AssetTreeModule {}
