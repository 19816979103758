import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';

import { toSignal } from '@angular/core/rxjs-interop';
import { RequestCache } from '@logic-suite/shared/cache/request.cache.service';
import { CustomerLookupServiceAdapter } from '@logic-suite/shared/components/lookups/customer-lookup-adapter.token';
import { ICustomer } from '@logic-suite/shared/models/customer.model';
import { ApplicationStorageService } from '@logic-suite/shared/storage';
import { retryOn504 } from '@logic-suite/shared/utils';
import { Cookie } from '@logic-suite/shared/utils/cookies';
import { objToString } from '@logic-suite/shared/utils/stringUtils';

@Injectable({ providedIn: 'root' })
export class CustomerService implements CustomerLookupServiceAdapter {
  private http = inject(HttpClient);
  private cache = inject(RequestCache);
  private app = inject(ApplicationStorageService);

  _selectedCustomer$ = new BehaviorSubject<ICustomer | null>(null);
  nullableCustomer$ = this._selectedCustomer$.asObservable();
  selectedCustomer$: Observable<ICustomer> = this._selectedCustomer$.asObservable().pipe(
    filter((c) => c?.customerID != null),
    map((c) => c as ICustomer),
  );

  selectedCustomerId$ = this.selectedCustomer$.pipe(map((customer) => customer.customerID));
  selectedCustomerId = toSignal(this.selectedCustomerId$);
  selectedCustomer = toSignal(this.selectedCustomer$);

  getSelectedCustomerId(): number | null {
    return this.selectedCustomer()?.customerID || null;
  }

  constructor() {
    this.get();
  }

  /**
   * List of customers this user has access to
   */
  getCustomerList() {
    return this.http.get<ICustomer[]>(`/api/access/Customer`);
  }

  /**
   * Takes either customerID or customerName as input
   */
  search(val: string) {
    return this.http.get<ICustomer[]>(`/api/shared/Customer?filter=${encodeURIComponent(val)}`).pipe(retryOn504());
  }

  /**
   * Use only for users where
   */
  loadCorporateBranches() {
    return this.selectedCustomer$.pipe(
      first(),
      switchMap((customer) =>
        this.http.get<ICustomer[]>(`/api/shared/Customer/CorporateBranches/${customer.customerID}`),
      ),
    );
  }

  set(customer: ICustomer) {
    const current = this._selectedCustomer$.value;
    if (current?.customerID === customer.customerID) return;

    Cookie.set('customer', btoa(objToString(customer)));
    this.app.removeItem('cache.access');
    this.cache.invalidate();
    this._selectedCustomer$.next(customer);

    // Filter out current customer from history
    const history: ICustomer[] = this.app
      .getItem('customer.history', [])
      .filter(
        (c: ICustomer, i: number, self: ICustomer[]) =>
          c.customerID !== customer.customerID && i === self.findIndex((c2) => c2.customerID === c.customerID),
      );

    // Add current customer to the beginning of the history
    history.unshift(customer);

    // Keep only 7 records. Remove the oldest
    if (history.length > 7) history.pop();
    this.app.replaceItem('customer.history', history);
  }

  remove() {
    Cookie.remove('customer');
    this.app.removeItem('cache.access');
    this.app.removeItem('access'); // Cleanup legacy
    this._selectedCustomer$.next(null);
  }

  get(): ICustomer {
    let customer = this._selectedCustomer$.value;
    if (!customer) {
      // Customer not set. Try to fetch from cookie
      const customerCookie = Cookie.get('customer') || btoa('{}');
      customer = JSON.parse(atob(customerCookie));
      this._selectedCustomer$.next(customer);
    }
    return customer || ({} as ICustomer);
  }
}
