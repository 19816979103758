import { Input, Directive, OnInit } from '@angular/core';
import { AbstractIconComponent } from './abstract-icon.component';

@Directive()
export class AbstractTreeIconComponent extends AbstractIconComponent implements OnInit {
  originalFill?: string;

  @Input() size = 40;
  @Input() set disabled(flag: boolean) {
    this.el.nativeElement.classList[flag ? 'add' : 'remove']('disabled');
  }
  dotColor = '#F79530';

  ngOnInit(): void {
    super.ngOnInit();
    this.el.nativeElement.style.height = this.size + 'px';
    this.el.nativeElement.style.width = this.size + 'px';
  }
}
