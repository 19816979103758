import { Component } from '@angular/core';
import { AbstractTreeIconComponent } from './abstract-tree-icon.component';

@Component({
  selector: 'app-icon-meter-gas',
  styleUrls: ['./icons.scss', './icons-tree.scss'],
  standalone: true,
  template: ` <svg viewBox="0 0 60 60" [attr.width]="size" [attr.height]="size" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M37.6,32.2l-8.4-13.9c-0.1-0.2-0.3-0.3-0.6-0.4c-0.3,0-0.5,0.2-0.6,0.4l-7.4,14.2c-1.8,3.5-0.4,7.4,3.5,9.6  c1.6,0.9,3.4,1.4,5.1,1.4c1.8,0,3.6-0.5,5.2-1.4c2.1-1.2,3.6-2.9,4.1-4.8C38.8,35.6,38.5,33.9,37.6,32.2z M34.2,39  c-0.3,1-1.1,1.9-2.2,2.6c-1.9,0.7-3.8,0.7-5.7,0c0,0,0,0-0.1,0c-2.1-1.2-2.8-3.2-1.9-5l4.4-8.5l5,8.3C34.3,37.2,34.5,38.1,34.2,39z   M36.9,36.9c-0.3,1.3-1.2,2.4-2.5,3.4c0.2-0.4,0.4-0.7,0.5-1.1c0.3-1.1,0.1-2.2-0.5-3.2l-5.4-8.9c-0.1-0.1-0.2-0.2-0.3-0.2  c-0.1,0-0.3,0.1-0.3,0.2l-4.7,9.1c-0.7,1.4-0.6,2.9,0.2,4.1c-2.5-1.8-3.4-4.6-2.1-7.1l6.8-13.1L36.3,33  C37.1,34.3,37.3,35.6,36.9,36.9z" />
    </g>
  </svg>`,
})
export class IconMeterGasComponent extends AbstractTreeIconComponent {}
