import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../auth';
import { Debouncer, objToString } from '../utils';
import { getEnv } from '../utils/getEnv';
import { getLocalStorage } from '../utils/getLocalStorage';
import { AbstractStorage } from './abstract-storage';

const storageKey = getEnv('storageKey', 'mySuite');
const EOF = ';';

/**
 * Service for storing application settings
 *
 * Settings are stored in exactly 1 key in localStorage. The value is a
 * stringified and base64 encoded JSON object. On startup this string is decoded
 * and stored as a local variable in this class. When the settings are changed
 * they are encoded and stored back in localStorage.
 *
 * The settings are also synced to the backend, so that they can be shared
 * between devices.
 */
@Injectable({ providedIn: 'root' })
export class ApplicationStorageService extends AbstractStorage {
  private http = inject(HttpClient);
  private auth = inject(AuthService);
  private storage?: Storage;

  // The encoded values as retreived from the backend
  private dbValues = '';

  constructor() {
    super();

    // Load and decode personal settings already in localStorage
    const valueStr = this.collectValues();
    this.values = this.decode(valueStr);
  }

  /**
   * Should be run as an app initializer.
   *
   * The application storage will be initialized with values already in
   * localStorage, but refreshed by values from the backend. Ideally these
   * two values should be the same, but if user is using multiple devices
   * we want to share these settings between devices. So this will sync up
   * remote settings to local settings.
   */
  async loadFromDb() {
    // NOTE: Do not try to run this method in the constructor. It will
    // cause a circular dependency between the AuthService and the
    // ApplicationStorageService.
    try {
      const settings = await firstValueFrom(this.http.get<string>(`/api/shared/ClientSettings?key=${storageKey}`));
      this.dbValues = settings || '';
      if (settings) {
        const values = this.decode(settings);
        // Overwrite local settings with persisted values
        Object.assign(this.values, values);
      }
      return true;
    } catch (ex) {
      console.error('DEBUG: Cannot refresh settings from backend.', ex);
      return false;
    }
  }

  /**
   * Every time the local settings change, we want to sync them to the backend.
   */
  @Debouncer(500)
  async storeToDb() {
    if (!this.auth.isLoggedIn()) return;
    // Do not persist cache
    const value = structuredClone(this.values);
    delete value.cache;
    delete value.featureFlags; // TODO: This should probably be stored under `cache` as well
    // Encode and store the values
    const valueStr = this.encode(value);
    if (this.dbValues !== valueStr) {
      try {
        // Values changed from last sync. Post them to the backend.
        await firstValueFrom(this.http.post(`/api/shared/ClientSettings`, { key: storageKey, value: valueStr }));
        // Refresh the local copy of the settings
        this.dbValues = valueStr;
      } catch (ex) {
        console.error('DEBUG: Cannot store settings to backend.', ex);
      }
    }
  }

  getStorage() {
    if (!this.storage) {
      this.storage = getLocalStorage();
    }
    return this.storage;
  }

  /**
   * Encode and store values
   */
  protected storeCurrent() {
    try {
      const storage = this.getStorage();

      // Remove previous buffer from storage to reduce overflowing
      this.reset();
      // Encode the values to a string
      const valueStr = this.encode(this.values);
      // Split the value string into chunks and store them in localStorage
      this.distributeValues(valueStr);
      // Queue up a sync job to the backend with the updated values
      this.storeToDb();
    } catch (ex) {
      console.error(this.values, ex);
    }
  }

  /**
   * Clear the localStorage of all chunks related to the storageKey
   */
  reset() {
    const storage = this.getStorage();
    const keys: string[] = [];
    for (let j = 0; j < storage.length; j++) {
      storage.key(j)?.includes(storageKey) && keys.push(String(storage.key(j)));
    }
    keys.forEach((key) => storage.removeItem(key));
  }

  // #region private methods
  /**
   * Collect a distrubuted value set from storage and return it as a string
   *
   * localStorage has a limit to how large a value is allowed to be. When stored,
   * we therefore split the value into chunks and store them separately using the
   * `storageKey` with each additional split adding a `_` suffix to the key.
   *
   * This method is responsible for collecting all the chunks in order and
   * return the value as a single string.
   *
   * @param storage
   * @returns an encoded value string
   */
  private collectValues(storage = this.getStorage()) {
    const keys: string[] = [];
    const value: string[] = [];
    for (let j = 0; j < storage.length; j++) {
      storage.key(j)?.includes(storageKey) && keys.push(String(storage.key(j)));
    }
    keys.sort((a, b) => (a.length < b.length ? -1 : 1)).forEach((key) => value.push(storage.getItem(key) || ''));

    return value.length > 0 ? value.join('') : '{}';
  }

  /**
   * Decode a value string
   *
   * In order to safely split the values into chunks, we need to encode the value
   * to a string. This method is responsible for decoding the value string back
   * into a value object.
   *
   * @param valueStr
   * @returns the decoded value object
   */
  private decode(valueStr: string) {
    let values = {};
    try {
      // Sometimes when storage is shared between a lot of environments
      // (such as it is when debugging localhost), the chunked storage can
      // have residue from larger configurations. This will make sure
      // that we skip all chunks which are part of this overflow.
      const str = valueStr.substring(0, valueStr.indexOf(EOF) > 0 ? valueStr.indexOf(EOF) : valueStr.length);
      // Decrypt the valuestring (NOTE! The previous step sometimes fails,
      // so we have to fallback to the original valuestring)
      const decoded = window.atob(str || valueStr);
      // Decode the valuestring
      const unescaped = decodeURIComponent(decoded);
      // Parse the valuestring
      values = JSON.parse(unescaped);
    } catch (ex) {
      const err = 'DEBUG: Cannot parse previous settings. Starting blank...';
    }
    return values;
  }

  /**
   * Encode a value object
   *
   * In order to safely split the values into chunks, we need to encode the value
   * to a string. This method is responsible for doing the encoding.
   *
   * @param value
   * @returns the encoded value string
   */
  private encode(value: Record<string, unknown> = this.values) {
    const str = objToString(value);
    const escaped = encodeURIComponent(str);
    const encoded = window.btoa(escaped);
    return encoded + EOF;
  }

  /**
   * Distribute values to storage.
   *
   * localStorage has a limit to how large a value is allowed to be. When stored,
   * we therefore split the value into chunks and store them separately using the
   * `storageKey` with each additional split adding a `_` suffix to the key.
   * Each chunk has a maximum size of 100000 characters.
   *
   * This method is responsible for distributing the value string into chunks.
   */
  private distributeValues(valueStr: string, storage = this.getStorage()) {
    // Split into chunks so that we avoid hitting browser limits
    const buffer = valueStr.match(/.{1,100000}/g);
    buffer?.forEach((v, i) => {
      const suffix = [...new Array(i)].map(() => '_').join('');
      storage.setItem(storageKey + suffix, v);
    });
  }
}
