import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { defer, map, Observable, of, Subject } from 'rxjs';
import { ApplicationStorageService } from '../storage';
import { AZURE_APP_CONFIGURATION_CLIENT } from './azure-app-confiuration';
import { FeatureFlag } from './feature-flag.interface';

// TODO: Do not cross-import between apps and libs. Find a better way to share this service.
import { CustomerService } from 'apps/suite/src/app/shared/access';

interface FeatureFlagState {
  flags: FeatureFlag[];
  loaded: boolean;
  error: string | null;
}

/**
 * Service to manage feature flags stored in App Configuration.
 */
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private appConfigurationClient = inject(AZURE_APP_CONFIGURATION_CLIENT);
  private applicationStorageService = inject(ApplicationStorageService);
  private customerService = inject(CustomerService);

  // state
  private state = signal<FeatureFlagState>({
    flags: [],
    loaded: false,
    error: null,
  });

  // selectors
  flags = computed(() => this.state().flags);
  enabledFlagIds = computed(() => {
    // Triggers
    const flags = this.flags();
    const customer = this.customerService.selectedCustomer();

    // Result
    return (
      flags
        .filter((flag) => {
          if (!flag.enabled && flag.conditions != null && 'client_filters' in flag.conditions) {
            // Might still be enabled for specific parameters
            const params = (flag.conditions.client_filters! as []).find((filter: any) => filter.name === 'enabledFor');
            if (params != null && 'parameters' in params) {
              if ('customer' in params['parameters'] && customer != null) {
                // Check if we should be enabled for a list of customers
                if (
                  (params['parameters']['customer'] as string)
                    .split(',')
                    .map((c) => c.trim())
                    .includes(`${customer?.customerID}`)
                ) {
                  return true;
                }
              }
            }
          }
          return flag.enabled;
        })
        // .filter((flag) => flag.enabled && flag.id !== 'chameleon/rail')
        .map((flag) => flag.id)
    );
  });
  loaded = computed(() => this.state().loaded);
  error = computed(() => this.state().error);

  allWidgetFlags = computed(() =>
    this.flags()
      .filter((flag) => flag.id?.startsWith('widget/'))
      .map((flag) => flag.id.split('/')[1]),
  );

  enabledWidgetFlags = computed(() =>
    this.enabledFlagIds()
      .filter((string) => string.startsWith('widget/'))
      .map((string) => string.split('/')[1]),
  );

  // sources
  private loadFromStorage$ = this.loadFeatureFlags();
  private fetchFromRemote$ = this.fetchFeatureFlags('noova-energy');
  add$ = new Subject<FeatureFlag>();
  edit$ = new Subject<FeatureFlag>();

  constructor() {
    // region reducers

    this.loadFromStorage$.pipe(takeUntilDestroyed()).subscribe({
      next: (flags: FeatureFlag[]) => {
        this.state.update((state) => ({
          ...state,
          flags: flags,
          loaded: true,
          error: null,
        }));
      },
      error: (err) => this.state.update((state) => ({ ...state, error: err })),
    });
    this.fetchFromRemote$.pipe(takeUntilDestroyed()).subscribe((flag: FeatureFlag) => {
      this.hasFlagWithId(flag.id) ? this.edit$.next(flag) : this.add$.next(flag);
    });

    this.add$.pipe(takeUntilDestroyed()).subscribe((newFlag: FeatureFlag) => {
      this.state.update((state) => ({
        ...state,
        flags: [...state.flags, newFlag],
      }));
    });

    this.edit$.pipe(takeUntilDestroyed()).subscribe((updatedFlag: FeatureFlag) => {
      this.state.update((state) => ({
        ...state,
        flags: state.flags.map((flag) => (flag.id === updatedFlag.id ? { ...flag, ...updatedFlag } : flag)),
      }));
    });

    // endregion

    effect(() => {
      // Store flags after they have loaded.
      if (this.flags().length) {
        this.saveFeatureFlags(this.flags());
      }
    });
  }

  // region private

  /**
   * Fetches all feature flags from App Configuration based on the labelFilter.
   */
  private fetchFeatureFlags(labelFilter: string): Observable<FeatureFlag> {
    return defer(() => this.appConfigurationClient.listConfigurationSettings({ labelFilter })).pipe(
      map((settings) => JSON.parse(settings.value!)),
    );
  }

  /**
   * Checks if there exists a flag in storage with the given id in storage.
   * @param id of the flag we are looking for.
   */
  private hasFlagWithId(id: string): boolean {
    return this.flags().some((flag) => flag.id === id);
  }

  // endregion

  // region storage

  private loadFeatureFlags() {
    this.applicationStorageService.removeItem('featureFlags'); // Remove deprecated storage
    const featureFlags = this.applicationStorageService.getItem('cache.featureFlags');
    return of(featureFlags ? (featureFlags as FeatureFlag[]) : []);
  }

  private saveFeatureFlags(featureFlags: FeatureFlag[]) {
    this.applicationStorageService.setItem('cache.featureFlags', featureFlags);
  }

  // endregion
}
