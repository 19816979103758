import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { rxResource, toObservable } from '@angular/core/rxjs-interop';
import { ApplicationStorageService } from '@logic-suite/shared/storage';
import { catchError, filter, of, tap } from 'rxjs';
import { Feature } from './access.model';
import { CustomerService } from './customer.service';

/**
 *
 * We collect access features from 2 different locations.
 *
 * 1. From the /api/access/User/{customerID}/Features endpoint
 *    - Contains information on which application features the customer
 *      has access to.
 * 2. From the /api/access/User/Me endpoint
 *    - Contains information on which features the user has access to.
 *
 * The responsibility of this service, is to provide access information
 */
@Injectable({ providedIn: 'root' })
export class FeaturesService {
  private readonly http = inject(HttpClient);
  private readonly customerService = inject(CustomerService);
  private readonly storage = inject(ApplicationStorageService);

  features = rxResource({
    request: () => ({
      customerID: this.customerService.selectedCustomer()?.customerID,
    }),
    loader: ({ request }) => {
      if (request.customerID == null) return of(this.storage.getItem('cache.access.features', []));
      return this.http.get<Feature[]>(`/api/access/User/${request.customerID}/Features`).pipe(
        tap((f) => this.storage.setItem('cache.access.features', f)),
        catchError(() => of([] as Feature[])),
      );
    },
  });
  features$ = toObservable(this.features.value).pipe(filter((f) => !!f && f.length > 0));

  getFeatureMatch(featureName: string, currentAppId: number, crossApp = false): Feature | null {
    return this.features.value()?.find((f: Feature) => {
      // .Match application ID if `crossApp` is not set
      if (!crossApp && !(f.applicationID == null || f.applicationID === currentAppId)) return null;
      // Match the name of the feature or the type
      // Known types to match are: 'Notification', 'Widget', 'Feature', 'Module'
      return f.featureName.toLowerCase() === featureName.toLowerCase() || f.featureType.toLowerCase() === featureName.toLowerCase();
    });
  }
}
