import { Component } from '@angular/core';
import { AbstractTreeIconComponent } from './abstract-tree-icon.component';

@Component({
  selector: 'app-icon-meter-heat',
  styleUrls: ['./icons.scss', './icons-tree.scss'],
  standalone: true,
  template: ` <svg viewBox="0 0 60 60" [attr.width]="size" [attr.height]="size" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="m 38.2,49.2 c 0,0 -0.1,0 -0.1,0 -1.2,-0.1 -2.3,-0.5 -3.3,-1.1 -2.9,-1.7 -4.7,-4 -5.1,-7 -0.3,-2.2 0.1,-4.4 1.4,-6.5 0.6,-1.1 1.3,-2.1 2,-3.1 0.4,-0.7 0.9,-1.3 1.3,-2 1,-1.5 1.1,-2.5 0.4,-3.6 -0.1,-0.1 -0.1,-0.3 -0.2,-0.5 -0.2,-0.5 -0.1,-1.1 0.2,-1.5 0.4,-0.4 0.8,-0.6 1.3,-0.4 1.3,0.5 2.3,1.1 3.1,1.8 1.6,1.6 2.2,3.5 2,6.1 -0.2,1.9 -0.8,3.9 -2,6 -0.7,1.2 -1.4,2.5 -1.8,3.9 -0.5,1.7 -0.1,3.2 1,4.8 0.1,0.2 0.3,0.4 0.5,0.6 l 0.3,0.3 c 0.3,0.4 0.4,1 0.1,1.4 -0.2,0.5 -0.7,0.8 -1.1,0.8 z M 37.7,27.7 c -0.1,1.5 -0.9,2.7 -1.2,3.3 -0.4,0.7 -0.9,1.3 -1.3,2 -0.7,1 -1.3,2 -2,3 -0.9,1.5 -1.3,3.1 -1.1,4.7 0.3,1.8 1.2,3.3 2.8,4.5 -0.5,-1.5 -0.6,-3 -0.1,-4.6 0.5,-1.7 1.3,-3.2 2,-4.5 1,-1.8 1.5,-3.3 1.7,-4.9 0.2,-1.4 -0.1,-2.6 -0.8,-3.5 z m -8.9,22.6 c 0,0 -0.1,0 -0.1,0 -1.9,-0.2 -3.6,-0.7 -5.3,-1.7 -4.8,-2.8 -7.5,-6.4 -8.2,-11.2 -0.5,-3.5 0.2,-7 2.3,-10.3 1.1,-1.8 2.2,-3.5 3.4,-5.3 0.7,-1.1 1.5,-2.2 2.2,-3.3 1.8,-2.9 2,-4.9 0.8,-7 -0.1,-0.2 -0.2,-0.4 -0.3,-0.7 L 23.5,10.5 C 23.3,10 23.4,9.5 23.7,9.1 24,8.7 24.5,8.6 25,8.7 c 2.1,0.8 3.6,1.7 4.9,2.9 2.5,2.4 3.5,5.6 3.1,9.6 -0.3,3.1 -1.4,6.3 -3.3,9.7 -1.2,2.1 -2.4,4.3 -3.1,6.7 -0.8,3.1 -0.2,6 1.9,8.9 0.3,0.4 0.5,0.7 0.8,1.1 l 0.5,0.6 c 0.3,0.4 0.4,1 0.1,1.4 -0.2,0.5 -0.6,0.7 -1.1,0.7 z M 27.1,12.7 c 0.6,2.9 -0.7,5.4 -1.8,7.2 -0.7,1.1 -1.5,2.2 -2.2,3.4 -1.1,1.7 -2.3,3.4 -3.3,5.2 -1.7,2.8 -2.3,5.7 -1.9,8.6 0.6,3.9 2.9,7 6.9,9.3 0.3,0.2 0.6,0.3 0.8,0.4 -1.9,-3.2 -2.3,-6.4 -1.4,-9.8 0.7,-2.7 2.1,-5.2 3.3,-7.3 1.8,-3.1 2.7,-5.9 3,-8.7 0.3,-3.2 -0.4,-5.6 -2.3,-7.5 -0.4,-0.3 -0.8,-0.6 -1.1,-0.8 z" />
      <path d="M19.1,38.1c-0.5-3.4,0.2-6.7,2.2-9.9l1.1,0.7c-1.8,2.9-2.5,6-2,9L19.1,38.1z" />
    </g>
  </svg>`,
})
export class IconMeterHeatComponent extends AbstractTreeIconComponent {}
