import { Component } from '@angular/core';
import { AbstractTreeIconComponent } from './abstract-tree-icon.component';

@Component({
  selector: 'app-icon-meter-group',
  styleUrls: ['./icons.scss', './icons-tree.scss'],
  standalone: true,
  template: ` <svg viewBox="0 0 60 60" [attr.width]="size" [attr.height]="size" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M33.7,41.1h-7.3c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h7.3c0.6,0,1,0.5,1,1C34.8,40.7,34.3,41.1,33.7,41.1z" />
      <path
        d="m 30.1,47 c -9.3,0 -16.8,-7.5 -16.8,-16.8 0,-9.3 7.5,-16.8 16.8,-16.8 9.3,0 16.8,7.6 16.8,16.8 0,9.3 -7.5,16.8 -16.8,16.8 z m 0,-31.5 C 22,15.5 15.4,22.1 15.4,30.2 15.4,38.3 22,45 30.1,45 38.2,45 44.8,38.4 44.8,30.3 44.8,22.2 38.2,15.5 30.1,15.5 Z M 17.7,36.6 C 16.9,35 16.4,33.3 16.3,31.5 l 2.1,-0.2 c 0.1,1.5 0.5,3 1.2,4.3 z m 24.8,0 -1.8,-0.9 c 0.7,-1.3 1.1,-2.8 1.2,-4.3 l 2.1,0.2 c -0.2,1.7 -0.7,3.4 -1.5,5 z M 18.3,29.3 16.2,29.1 c 0.1,-1.6 0.6,-3.2 1.3,-4.7 l 1.9,0.9 c -0.6,1.3 -0.9,2.6 -1.1,4 z m 2.1,-5.8 -1.7,-1.2 c 0.9,-1.3 2.1,-2.5 3.4,-3.4 l 1.2,1.7 c -1.1,0.8 -2.1,1.8 -2.9,2.9 z m 21.5,5.8 c -0.1,-1.4 -0.5,-2.7 -1.1,-4 l 1.9,-0.9 c 0.7,1.5 1.1,3.1 1.3,4.7 z M 39.8,23.6 C 39,22.5 38,21.5 36.9,20.7 L 38.1,19 c 1.3,0.9 2.5,2.1 3.4,3.4 z m -4.7,-4 c -1.3,-0.6 -2.6,-1 -4,-1.1 l 0.2,-2.1 c 1.6,0.1 3.2,0.6 4.7,1.3 z m -10,0 -0.9,-1.9 c 1.5,-0.7 3.1,-1.1 4.7,-1.3 l 0.2,2.1 c -1.4,0.2 -2.7,0.5 -4,1.1 z" />
      <path
        d="M29.3,35.7c-0.8,0-1.5-0.3-2.1-0.9c-1.1-1.1-1.1-3,0-4.1l9.2-5.9c0.2-0.1,0.5-0.1,0.6,0.1c0.2,0.2,0.2,0.4,0.1,0.6     l-5.5,9.3C30.9,35.4,30.1,35.7,29.3,35.7z M35,26.9l-7.2,4.6c-0.7,0.7-0.7,1.9,0.1,2.6c0.8,0.8,2,0.8,2.8,0L35,26.9z" />
      <path
        d="m 38.2,13.4 c -0.9,0 -1.8,0.1 -2.7,0.2 1.9,0.4 3.6,1.1 5.2,2.1 6.9,1.3 12.2,7.3 12.2,14.5 0,7.3 -5.3,13.3 -12.2,14.5 -1.6,1 -3.3,1.7 -5.2,2.1 0.9,0.1 1.7,0.2 2.7,0.2 C 47.4,47 55,39.5 55,30.2 55,21 47.4,13.4 38.2,13.4 Z m 10.5,22.2 1.8,0.9 c 0.8,-1.6 1.3,-3.3 1.4,-5.1 l -2.1,-0.2 c 0,1.6 -0.4,3.1 -1.1,4.4 z m 1.2,-6.3 2.1,-0.2 c -0.1,-1.6 -0.6,-3.2 -1.3,-4.7 l -1.9,0.9 c 0.6,1.3 1,2.6 1.1,4 z m -2.1,-5.7 1.7,-1.2 C 48.6,21.1 47.4,19.9 46.1,19 l -0.7,1 c 0.7,0.9 1.3,1.8 1.7,2.8 0.3,0.2 0.5,0.5 0.7,0.8 z" />
      <path
        d="m 21.6,13.4 c 0.9,0 1.8,0.1 2.7,0.2 -1.9,0.4 -3.6,1.1 -5.2,2.1 -7,1.3 -12.3,7.3 -12.3,14.5 0,7.3 5.3,13.3 12.2,14.5 1.6,1 3.3,1.7 5.2,2.1 C 23.3,46.9 22.5,47 21.5,47 12.2,47 4.7,39.5 4.7,30.2 4.8,21 12.3,13.4 21.6,13.4 Z M 11,35.6 9.2,36.5 C 8.4,34.9 7.9,33.2 7.8,31.4 l 2.1,-0.2 c 0,1.6 0.5,3.1 1.1,4.4 z M 9.8,29.3 7.7,29.1 C 7.8,27.5 8.3,25.9 9,24.4 l 1.9,0.9 c -0.6,1.3 -1,2.6 -1.1,4 z m 2.1,-5.7 -1.7,-1.2 c 0.9,-1.3 2.1,-2.5 3.4,-3.4 l 0.7,1 c -0.7,0.9 -1.3,1.8 -1.7,2.8 -0.2,0.2 -0.5,0.5 -0.7,0.8 z" />
    </g>
  </svg>`,
})
export class IconMeterGroupComponent extends AbstractTreeIconComponent {}
