import { Component } from '@angular/core';
import { AbstractTreeIconComponent } from './abstract-tree-icon.component';

@Component({
  selector: 'app-icon-meter-cold',
  styleUrls: ['./icons.scss', './icons-tree.scss'],
  standalone: true,
  template: ` <svg viewBox="0 0 60 60" [attr.width]="size" [attr.height]="size" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M47.2,36.9l-2-1.1l1.5-0.4c0.8-0.2,1.2-1,1-1.8c-0.2-0.8-1-1.2-1.8-1l-4.3,1.1l-3.3-1.9v-6.1l3.3-1.9L46,25   c0.1,0,0.3,0,0.4,0c0.6,0,1.2-0.4,1.4-1.1c0.2-0.8-0.3-1.6-1-1.8l-1.5-0.4l2-1.1c0.7-0.4,0.9-1.3,0.5-2c-0.4-0.7-1.3-0.9-2-0.5   l-2,1.1l0.4-1.5c0.2-0.8-0.3-1.6-1-1.8c-0.8-0.2-1.6,0.3-1.8,1l-1.1,4.3l-3.4,2l-4.9-2.8v-3.8l3.1-3.1c0.6-0.6,0.6-1.5,0-2   c-0.6-0.6-1.5-0.6-2,0l-1.1,1.1v-2.3c0-0.8-0.6-1.4-1.4-1.4S29,9.5,29,10.3v2.3l-1.1-1.1c-0.6-0.6-1.5-0.6-2,0   c-0.6,0.6-0.6,1.5,0,2l3.1,3.1v3.7l-5.2,3l-3.2-1.9l-1.1-4.3c-0.2-0.8-1-1.2-1.8-1c-0.8,0.2-1.2,1-1,1.8l0.4,1.5l-2-1.1   c-0.7-0.4-1.6-0.2-2,0.5c-0.4,0.7-0.2,1.6,0.5,2l2,1.1l-1.5,0.4c-0.8,0.2-1.2,1-1,1.8c0.2,0.6,0.8,1.1,1.4,1.1c0.1,0,0.3,0,0.4,0   l4.3-1.1l3.3,1.9v5.7l-3.4,2l-4.3-1.1c-0.8-0.2-1.6,0.3-1.8,1c-0.2,0.8,0.3,1.6,1,1.8l1.5,0.4l-2,1.1c-0.7,0.4-0.9,1.3-0.5,2   c0.3,0.5,0.8,0.7,1.3,0.7c0.2,0,0.5-0.1,0.7-0.2l2-1.1l-0.4,1.5c-0.2,0.8,0.3,1.6,1,1.8c0.1,0,0.3,0,0.4,0c0.6,0,1.2-0.4,1.4-1.1   l1.1-4.3l3.3-1.9l5.2,3v3.9l-3.1,3.1c-0.6,0.6-0.6,1.5,0,2s1.5,0.6,2,0l1.1-1.1v2.3c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4v-2.3   l1.1,1.1c0.3,0.3,0.7,0.4,1,0.4s0.7-0.1,1-0.4c0.6-0.6,0.6-1.5,0-2l-3.1-3.1v-3.9l5-2.9l3.4,1.9l1.1,4.3c0.2,0.6,0.8,1.1,1.4,1.1   c0.1,0,0.3,0,0.4,0c0.8-0.2,1.2-1,1-1.8l-0.4-1.5l2,1.1c0.2,0.1,0.5,0.2,0.7,0.2c0.5,0,1-0.3,1.3-0.7   C48.2,38.2,47.9,37.3,47.2,36.9z M30.4,34.7l-5.1-2.9v-5.9l5.1-2.9l5.1,2.9v5.9L30.4,34.7z" />
      <path
        d="m 31,26.4 1.5,1 v 1.9 H 34 V 26.6 L 31.8,25.2 Z M 24.999999,19 a 0.69999999,0.69999999 0 0 1 -0.7,0.7 0.69999999,0.69999999 0 0 1 -0.7,-0.7 0.69999999,0.69999999 0 0 1 0.7,-0.7 0.69999999,0.69999999 0 0 1 0.7,0.7 z M 36.7,18.700001 a 0.69999999,0.69999999 0 0 1 -0.7,0.7 0.69999999,0.69999999 0 0 1 -0.7,-0.7 0.69999999,0.69999999 0 0 1 0.7,-0.7 0.69999999,0.69999999 0 0 1 0.7,0.7 z m 5.5,10 a 0.69999999,0.69999999 0 0 1 -0.7,0.7 0.69999999,0.69999999 0 0 1 -0.7,-0.7 0.69999999,0.69999999 0 0 1 0.7,-0.7 0.69999999,0.69999999 0 0 1 0.7,0.7 z m -5.400002,9.899997 a 0.69999999,0.69999999 0 0 1 -0.7,0.7 0.69999999,0.69999999 0 0 1 -0.7,-0.7 0.69999999,0.69999999 0 0 1 0.7,-0.7 0.69999999,0.69999999 0 0 1 0.7,0.7 z m -11.499998,0 a 0.69999999,0.69999999 0 0 1 -0.7,0.7 0.69999999,0.69999999 0 0 1 -0.7,-0.7 0.69999999,0.69999999 0 0 1 0.7,-0.7 0.69999999,0.69999999 0 0 1 0.7,0.7 z M 19.7,28.6 a 0.69999999,0.69999999 0 0 1 -0.7,0.7 0.69999999,0.69999999 0 0 1 -0.7,-0.7 0.69999999,0.69999999 0 0 1 0.7,-0.7 0.69999999,0.69999999 0 0 1 0.7,0.7 z" />
    </g>
  </svg>`,
})
export class IconMeterColdComponent extends AbstractTreeIconComponent {}
