<ng-template #treeNode>
  @if (node() != null) {
    <app-asset-tree-icon [type]="node()!.type" [variant]="node()!.meteringPointTypeID"></app-asset-tree-icon>
    @if (node()!.type === 'root') {
      <span>{{ node()!.name | translate }}</span>
    } @else if (
      node()!.type === 'meteringPoint' && featureFlagService.enabledFlagIds().includes('energy-block/assignment/v1')
    ) {
      <div class="node-name-container">
        <div class="node-name">{{ node()!.name | translate }}</div>
        @if (activeEnergyBlocks().length > 0) {
          <div class="energy-block-chip-container">
            @for (energyBlockId of activeEnergyBlocks(); track $index) {
              <mat-chip
                data-testid="energy-block-chip"
                [style.background-color]="energyBlocksService.energyBlocksMap().get(energyBlockId)?.color ?? '#888888'"
                >{{ energyBlocksService.energyBlocksMap().get(energyBlockId)?.name ?? 'Unnamed' | translate }}
              </mat-chip>
            }
          </div>
        }
        @if (node()!.energyBlockIntervals) {}
      </div>
    } @else {
      <span>{{ node()!.name || params().value }}</span>
    }
  } @else {
    <span>{{ params().value }}</span>
  }
</ng-template>

@if (isLink()) {
  <a [routerLink]="routeTo().route" [queryParams]="routeTo().queryParams">
    <ng-container *ngTemplateOutlet="treeNode"></ng-container>
  </a>
} @else if (isFooter()) {
  <span>{{ params().value }}</span>
} @else {
  <ng-container *ngTemplateOutlet="treeNode"></ng-container>
}
