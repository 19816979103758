<!-- prettier-ignore -->
<table>
  <thead><tr><th colspan="2">Application info</th></tr></thead>
  <tbody>
    <tr><th>Name:</th><td>{{ app.getApplicationName() }}</td></tr>
    <tr><th>Environment:</th><td>{{ env }}</td></tr>
    <tr><th>Version:</th><td>{{ appVersion() }}</td></tr>
    <tr>
      <th>Updated:</th>
      <td>{{ updateDate() != null ? (updateDate() | date: 'dd.MM.yyyy HH:mm') : 'Not available' }}</td>
    </tr>
  </tbody>
  <thead><tr><th colspan="2">Client info</th></tr></thead>
  <tbody>
    <tr><th>Client:</th><td>{{ browserName }}</td></tr>
    <tr><th>Version:</th><td>{{ browserVersion }}</td></tr>
    <tr><th>Mobile:</th><td>{{ isMobile ? '☑️' : '⬛' }}</td></tr>
    <tr><th>UA:</th><td>{{ uaString() }}</td></tr>
  </tbody>
</table>

<footer>
  <button mat-raised-button type="button" color="primary" (click)="reload()">Refresh</button>
  <button mat-raised-button type="button" color="warn" (click)="hardReset()">Hard reset</button>
</footer>

@if (cache().length > 0) {
  <section>
    <h2 colspan="2">Cache</h2>
    @for (c of cache(); track c.key) {
      <article>
        <header (click)="c.shown = !c.shown">
          <span>{{ c.key }}</span>
          <span>{{ c.shown ? '▲' : '▼' }}</span>
        </header>
        @if (c.shown) {
          <table cellSpacing="0" cellPadding="0">
            <tbody>
              @for (detail of c.details; track detail.url) {
                <tr>
                  <th>{{ detail.date ? (detail.date | date: 'dd.MM.yyyy HH:mm') : '&nbsp;' }}</th>
                  <td>{{ detail.url }}</td>
                </tr>
              }
            </tbody>
          </table>
          <button mat-stroked-button type="button" color="warn" (click)="clearCache(c.key)">Empty cache</button>
        }
      </article>
    }
  </section>
}
