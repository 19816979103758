import { Directive, ElementRef, Input, OnInit, inject } from '@angular/core';

@Directive()
export class AbstractIconComponent implements OnInit {
  protected el = inject(ElementRef);

  @Input() size = 20;
  @Input() set disabled(flag: boolean) {
    this.el.nativeElement.classList[flag ? 'add' : 'remove']('disabled');
  }

  ngOnInit() {
    this.el.nativeElement.classList.add('app-icon');
  }
}
