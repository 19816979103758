import { Component } from '@angular/core';
import { AbstractTreeIconComponent } from './abstract-tree-icon.component';

export const ICON_METER = ` <svg viewBox="0 0 60 60" [attr.width]="size" [attr.height]="size" xmlns="http://www.w3.org/2000/svg">
<g>
  <path
    d="M33.2,40.3h-8.6c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4h8.6c0.8,0,1.4-0.6,1.4-1.4C34.6,40.9,34,40.3,33.2,40.3z"
  />
  <path
    d="m 28.9,9.9 c -11.1,0 -20.1,9 -20.1,20.1 0,11.1 9,20.1 20.1,20.1 C 40,50.1 49,41.1 49,30 49,18.9 40,9.9 28.9,9.9 Z m 0,37.4 c -9.5,0 -17.3,-7.8 -17.3,-17.3 0,-9.5 7.8,-17.3 17.3,-17.3 9.5,0 17.3,7.8 17.3,17.3 0,9.5 -7.7,17.3 -17.3,17.3 z M 12.3,31.5 c 0.2,2.1 0.8,4.2 1.7,6.1 l 2.5,-1.3 C 15.7,34.7 15.2,33 15.1,31.2 Z m 29,4.8 2.5,1.3 c 1,-1.9 1.5,-3.9 1.7,-6.1 l -2.8,-0.2 c -0.1,1.7 -0.6,3.4 -1.4,5 z M 16.4,24.2 13.8,23 c -0.8,1.8 -1.3,3.7 -1.5,5.6 l 2.8,0.2 c 0.2,-1.5 0.6,-3.1 1.3,-4.6 z m -1.1,-3.7 2.3,1.6 c 0.9,-1.3 2.1,-2.5 3.4,-3.4 l -1.6,-2.3 c -1.6,1.2 -3,2.5 -4.1,4.1 z m 27.4,8.4 2.8,-0.2 c -0.2,-2 -0.7,-3.8 -1.5,-5.6 l -2.5,1.2 c 0.6,1.4 1.1,3 1.2,4.6 z m -4.3,-12.4 -1.6,2.3 c 1.3,0.9 2.5,2.1 3.4,3.4 l 2.3,-1.6 C 41.4,19 40,17.6 38.4,16.5 Z M 36,15 c -1.8,-0.8 -3.7,-1.3 -5.6,-1.5 l -0.2,2.8 c 1.6,0.1 3.2,0.6 4.7,1.3 z m -8.5,-1.5 c -2,0.2 -3.8,0.7 -5.6,1.5 l 1.2,2.5 c 1.5,-0.7 3,-1.1 4.7,-1.2 z"
  />
  <path
    d="M36.2,23.5l-10.9,7c-1.4,1.4-1.4,3.7,0,5c0.7,0.7,1.6,1.1,2.6,1.1c1,0,1.9-0.4,2.7-1.2l6.6-11c0.2-0.3,0.1-0.6-0.1-0.9   C36.9,23.3,36.5,23.3,36.2,23.5z M29.5,34.6c-0.9,0.9-2.3,0.9-3.2,0c-0.4-0.4-0.6-1-0.6-1.5c0-0.6,0.2-1.1,0.5-1.4l8.3-5.3   L29.5,34.6z"
  />
</g>
</svg>`;
@Component({
  selector: 'app-icon-meter',
  styleUrls: ['./icons.scss', './icons-tree.scss'],
  standalone: true,
  template: ICON_METER,
})
export class IconMeterComponent extends AbstractTreeIconComponent {}
