import { Component } from '@angular/core';
import { AbstractTreeIconComponent } from './abstract-tree-icon.component';

@Component({
  selector: 'app-icon-asset',
  styleUrls: ['./icons.scss', './icons-tree.scss'],
  standalone: true,
  template: ` <svg viewBox="0 0 60 60" [attr.width]="size" [attr.height]="size" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M43.8,48.1V10.6c0-0.7-0.6-1.3-1.3-1.3H17.6c-0.7,0-1.3,0.6-1.3,1.3v37.5H9.5v2.7h41.1v-2.7H43.8z M32,47.6h-3.9v-6.6H32V47.6z M19,12h22.1v35.6h-7.7v-7.3c0-0.4-0.3-0.7-0.7-0.7h-5.3c-0.4,0-0.7,0.3-0.7,0.7v7.3H19V12z" />
      <path
        d="M22.9,19.6h4.3c0.4,0,0.7-0.3,0.7-0.7v-4.3c0-0.4-0.3-0.7-0.7-0.7h-4.3c-0.4,0-0.7,0.3-0.7,0.7v4.3C22.3,19.3,22.6,19.6,22.9,19.6z M23.6,15.3h2.9v2.9h-2.9V15.3z" />
      <path
        d="M32.9,19.6h4.3c0.4,0,0.7-0.3,0.7-0.7v-4.3c0-0.4-0.3-0.7-0.7-0.7h-4.3c-0.4,0-0.7,0.3-0.7,0.7v4.3C32.2,19.3,32.5,19.6,32.9,19.6z M33.6,15.3h2.9v2.9h-2.9V15.3z" />
      <path
        d="M22.9,28.5h4.3c0.4,0,0.7-0.3,0.7-0.7v-4.3c0-0.4-0.3-0.7-0.7-0.7h-4.3c-0.4,0-0.7,0.3-0.7,0.7v4.3C22.3,28.2,22.6,28.5,22.9,28.5z M23.6,24.3h2.9v2.9h-2.9V24.3z" />
      <path
        d="M32.9,28.5h4.3c0.4,0,0.7-0.3,0.7-0.7v-4.3c0-0.4-0.3-0.7-0.7-0.7h-4.3c-0.4,0-0.7,0.3-0.7,0.7v4.3C32.2,28.2,32.5,28.5,32.9,28.5z M33.6,24.3h2.9v2.9h-2.9V24.3z" />
      <path
        d="M27.2,37.4c0.4,0,0.7-0.3,0.7-0.7v-4.3c0-0.4-0.3-0.7-0.7-0.7h-4.3c-0.4,0-0.7,0.3-0.7,0.7v4.3c0,0.4,0.3,0.7,0.7,0.7H27.2z M23.6,33.2h2.9v2.9h-2.9V33.2z" />
      <path
        d="M32.9,37.4h4.3c0.4,0,0.7-0.3,0.7-0.7v-4.3c0-0.4-0.3-0.7-0.7-0.7h-4.3c-0.4,0-0.7,0.3-0.7,0.7v4.3C32.2,37.1,32.5,37.4,32.9,37.4z M33.6,33.2h2.9v2.9h-2.9V33.2z" />
    </g>
  </svg>`,
})
export class IconAssetComponent extends AbstractTreeIconComponent {}
