import { Component } from '@angular/core';
import { AbstractTreeIconComponent } from './abstract-tree-icon.component';

@Component({
  selector: 'app-icon-room',
  styleUrls: ['./icons.scss', './icons-tree.scss'],
  standalone: true,
  template: ` <svg viewBox="0 0 60 60" [attr.width]="size" [attr.height]="size" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="m 23.040379,21.448814 -0.01203,4.037947 c 0,0 0.05521,0.221898 0.279783,0.221974 0.224487,7.6e-5 0.280236,-0.21903 0.280236,-0.21903 l 0.01203,-4.036472 c 0,0 -0.06082,-0.166868 -0.27963,-0.171825 -0.224961,-0.0051 -0.280389,0.167406 -0.280389,0.167406 z M 28.5,47.4 h 15.8 c 0.7,0 1.3,-0.6 1.3,-1.3 v -30 c 0,-0.7 -0.6,-1.3 -1.3,-1.3 h -30 c -0.7,0 -1.3,0.6 -1.3,1.3 v 30 c 0,0.7 0.6,1.3 1.3,1.3 h 4.5 v -0.1 h 0.1 c 0.4,0 0.7,-0.3 0.7,-0.7 v -9.1 c 4.9,0.3 8.9,4.1 8.9,8.8 M 43,26 h -1.3 v -7 c 0,-0.2 -0.2,-0.3 -0.3,-0.3 H 36.8 V 17.4 H 43 Z M 22.2,17.4 V 30.7 H 15.7 V 17.4 Z m -3.3,19.5 v 0 c -0.4,0 -0.7,0.3 -0.7,0.7 v 7.2 H 15.6 V 31.4 h 6.9 c 0.2,0 0.3,-0.2 0.3,-0.3 V 17.5 H 36 V 19 c 0,0.2 0.2,0.3 0.3,0.3 H 41 v 7 c 0,0.2 0.2,0.3 0.3,0.3 H 43 v 7.7 h -1.6 c -0.2,0 -0.3,0.2 -0.3,0.3 v 9.9 H 28.9 c -0.8,-4.2 -5,-7.6 -10,-7.6 z m 22.8,7.8 V 35.1 H 43 v 9.6 z" />
      <!-- Room 3 -->
      <!-- <path
        d="M44.3,14.5h-30c-0.7,0-1.3,0.6-1.3,1.3v30c0,0.7,0.6,1.3,1.3,1.3h7.3v-2.7h-5.9V29.7h10v-3.8h-1.3v2.5h-8.7V17.2h8.7v4.6h1.3v-4.6h6.4v4.6h1.3v-4.6H43v11.2h-9.6v-2.5h-1.3v9.4h1.3v-5.6H43v14.8h-9.6v-5h-1.3v5h-3.6v2.7h15.8c0.7,0,1.3-0.6,1.3-1.3v-30C45.6,15.1,45,14.5,44.3,14.5z"
      /> -->

      <!-- Room 2 -->
      <!-- <path
        d="M28.5,47.4h15.8c0.7,0,1.3-0.6,1.3-1.3v-30c0-0.7-0.6-1.3-1.3-1.3h-30c-0.7,0-1.3,0.6-1.3,1.3v30c0,0.7,0.6,1.3,1.3,1.3h4.5v-0.1c0,0,0,0,0.1,0c0.4,0,0.7-0.3,0.7-0.7v-9.1c4.9,0.3,8.9,4.1,8.9,8.8 M43,26h-1.3v-7c0-0.2-0.2-0.3-0.3-0.3h-4.6v-1.3H43V26zM22.2,17.4v1.3h-6.5v-1.3H22.2z M18.9,36.9C18.9,36.9,18.9,36.9,18.9,36.9c-0.4,0-0.7,0.3-0.7,0.7v7.2h-2.6V19.4h6.9c0.2,0,0.3-0.2,0.3-0.3v-1.6h13.2V19c0,0.2,0.2,0.3,0.3,0.3H41v7c0,0.2,0.2,0.3,0.3,0.3H43v7.7h-1.6c-0.2,0-0.3,0.2-0.3,0.3v9.9H28.9C28.1,40.3,23.9,36.9,18.9,36.9z M41.7,44.7v-9.6H43v9.6H41.7z"
      /> -->
      <!-- Room 1 -->
      <!-- <path
        d="M47.6,9.6H11.8c-0.8,0-1.5,0.7-1.5,1.5v35.9c0,0.8,0.7,1.5,1.5,1.5h35.9c0.8,0,1.5-0.7,1.5-1.5V11.1C49.1,10.2,48.4,9.6,47.6,9.6z M13.2,39.3l6.2-6.2h3.6L13.2,43V39.3z M13.2,38.3v-3.6l1.6-1.6h3.6L13.2,38.3z M13.2,33.6v-0.5h0.5L13.2,33.6z M13.2,31.6V30l4.2-4.2h3.6l-5.9,5.9H13.2z M13.2,29v-3.2h3.2L13.2,29z M13.2,24.3v-3.6l8.1-8.1h3.4v0.2L13.2,24.3L13.2,24.3z M13.2,16l3.5-3.5h3.6l-7.1,7.1V16z M15.7,12.5L13.2,15v-2.4H15.7z M21.1,45.4l3.7-3.7v3.7H21.1z M34.6,45.4v-3.9h-1.5v3.9h-6.9v-3.9H25l0.6-0.6l-0.5-0.5l-5,5h-3.6l9.2-9.2l-0.5-0.5l-9.7,9.7h-2.2V44l10.9-10.9h0.7v2.4h1.5v-3.9h-0.7l0,0l-0.5-0.5l-0.6,0.6h-3.6l4.7-4.7l-0.5-0.5l-5.2,5.2h-3.6l5.9-5.9h4.1v-3.9h-1l-0.1-0.1L25,21.9h-0.2v0.2l-2.2,2.2h-3.6l6.7-6.7l-0.5-0.5l-7.2,7.2h-3.6l10.5-10.5v2.1h1.5v-3.3h6.9v3.3h1.5v-3.3h11.6v11.8H34.6v-2.4h-1.5v3.9h13v5.9h-13v3.9h1.5v-2.4h11.6v12.3H34.6zM23.6,24.3l1.2-1.2v1.2H23.6z"
      /> -->
    </g>
  </svg>`,
})
export class IconRoomComponent extends AbstractTreeIconComponent {}
