@if (dataSource.data.length) {
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node" [ngClass]="{ 'hidden-node': node.hidden === true || !unfiltered }">
      @if (!node.hidden || unfiltered || !isExpandable(node)) {
        <div>
          <div
            class="container h-10"
            [ngClass]="{ selected: isSelected(node), inactive: node.active === false }"
            title="{{ (node.active === false ? 'INACTIVE: ' : '') | translate }} {{ node.description || node.name }}">
            <a [routerLink]="node.url" [queryParams]="calcQueryParams(node)" [class]="node.type">
              <app-asset-node [node]="node"></app-asset-node>
              <!-- <app-asset-tree-icon [type]="node.type" [variant]="node.meteringPointTypeID"></app-asset-tree-icon>
              @if (node.type === 'root') {
                <span>{{ node.name | translate }}</span>
              } @else {
                <span>{{ node.name }}</span>
              } -->
            </a>
            @if (isExpandable(node)) {
              <button
                type="button"
                mat-icon-button
                (click)="toggleNode($event, node)"
                [attr.aria-label]="'toggle ' + node.name">
                <mat-icon class="leading-8"
                  >{{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                </mat-icon>
              </button>
            }
            @if (node.isLoading) {
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            }
          </div>
          @if (treeControl.isExpanded(node) && isExpandable(node)) {
            <div [ngClass]="{ sub: true, 'extended-sub': hasHiddenChildren(node) && !!showTrail }">
              <ng-container matTreeNodeOutlet></ng-container>
            </div>
          }
        </div>
      }
      @if (node.hidden && !unfiltered && isExpandable(node)) {
        @if (showTrail) {
          <span>{{ node.name }} / </span>
        }
        <ng-container matTreeNodeOutlet></ng-container>
      }
    </mat-nested-tree-node>

    <!-- This is the tree node template for expandable functions -->
    <mat-tree-node *matTreeNodeDef="let node; when: isFunction" [ngClass]="{ selected: isSelected(node) }">
      <a
        [routerLink]="node.url"
        [queryParams]="combineParams(node)"
        title="{{ node.description || node.name | translate }}">
        <app-asset-tree-icon [type]="node.type"></app-asset-tree-icon>
        <span>{{ node.name | translate }}</span>
      </a>
    </mat-tree-node>
  </mat-tree>
}

<ng-content></ng-content>

@if (!isLoading() && dataSource.data.length <= 0) {
  <div class="loader" translate>This affiliate has no assets</div>
}
