import { HttpClient } from '@angular/common/http';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { Params } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription, throwError, timer } from 'rxjs';
import { filter, map, retryWhen, shareReplay, switchMap } from 'rxjs/operators';

import { AppService } from '@logic-suite/shared/app.service';
import { RequestCache } from '@logic-suite/shared/cache/request.cache.service';
import { ICustomer } from '@logic-suite/shared/models/customer.model';
import { objToString } from '@logic-suite/shared/utils';

import { toSignal } from '@angular/core/rxjs-interop';
import { CustomerService } from '../../access';
import { AssetNode, AssetNodeType } from './node.model';

/**
 * @deprecated Use {@link HierarchyService} instead.
 */
@Injectable({ providedIn: 'root' })
export class AssetTreeService implements OnDestroy {
  private appService = inject(AppService);
  private cache = inject(RequestCache);
  private customerService = inject(CustomerService);
  private http = inject(HttpClient);

  private _nodeSelection$ = new BehaviorSubject<AssetNode>({} as AssetNode);
  nodeSelection$ = this._nodeSelection$.asObservable().pipe(filter((c) => !!c && Object.keys(c).length > 0));
  protected _currentAssetTree$ = new BehaviorSubject<AssetNode[]>([]);
  private _shouldReload$ = new BehaviorSubject<number>(0);
  private subscriptions: Subscription[] = [];
  isLoading$ = new BehaviorSubject(false);

  rootNode: AssetNode = {
    id: null,
    name: 'Your assets',
    description: 'Your assets',
    url: [],
    type: 'root',
  };

  loadAssetTree$ = new Subject<string>();

  getAssetTree(appId: string = '' + this.appService.getApplicationID()): Observable<AssetNode[]> {
    let retries = 0;
    return combineLatest([this._shouldReload$, this.customerService.selectedCustomer$]).pipe(
      map(([_, current]) => current),
      filter((current) => !!current && !!current.customerID),
      map((current) => current as ICustomer),
      switchMap((current) => {
        return this.http.get<AssetNode[]>(`/api/shared/Structure/${appId}/${current.customerID}`).pipe(
          retryWhen((errors) => {
            retries++;
            if (retries > 5) return throwError(() => errors); // Too many retries. Give up
            console.log('Failed to load asset-tree. Retrying...', retries);
            return timer(retries * 1000); // Retry after 1s, 2s, etc...
          }),
          shareReplay(1),
          switchMap((res) => {
            // Every item from this endpoint returns an AssetNode.
            // FunctionNodes are dynamically added later if at all.
            const mapModel = (model: AssetNode, parent?: AssetNode): AssetNode => {
              model.parent = parent;
              model.children = model.children?.map((m) => mapModel(m as AssetNode, model));
              model.url = [];
              return model;
            };

            this.rootNode.id = `${current.customerID}`;
            this.rootNode.children = JSON.parse(objToString(res)).map((m: AssetNode) => mapModel(m, this.rootNode));
            this._currentAssetTree$.next([this.rootNode]);
            return this._currentAssetTree$;
          }),
        );
      }),
    );
  }

  selectNode(node: AssetNode) {
    if (!node) {
      this._nodeSelection$.next(this.rootNode);
      return;
    }

    if (!this.isSelected(node)) {
      this._nodeSelection$.next(node);
    }
  }

  isSelected(node: AssetNode) {
    if (!node) return false;
    const selected = this.getSelectedNode();
    return (
      selected &&
      selected.type === node.type &&
      selected.id === node.id &&
      (selected.parent as AssetNode)?.id === (node.parent as AssetNode)?.id
    );
  }

  getSelectedNode = toSignal(this._nodeSelection$, { initialValue: { type: 'root' } as AssetNode });

  triggerReload() {
    this.cache.invalidate(`/api/shared/Structure`);
    this._shouldReload$.next(this._shouldReload$.value + 1);
  }

  /**
   * Finds all descendants of `data` which is of given type.
   */
  findAllNodes(type: AssetNodeType, data = this._currentAssetTree$.value as AssetNode[]) {
    return data.reduce((acc, node) => {
      if (node.type === type) {
        acc.push(node);
      }
      if (node.children?.length) {
        acc = acc.concat(this.findAllNodes(type, node.children as AssetNode[]));
      }
      return acc;
    }, [] as AssetNode[]);
  }

  /**
   * Find a specific node of given type and id
   */
  findNode(type: AssetNodeType, id: string | null, data = this._currentAssetTree$.value as AssetNode[]) {
    let node = null;

    if (id) {
      node = data.find((n) => n.id === id && n.type === type); // Try direct children first
      if (!node) {
        // Then search all descendants
        const types = this.findAllNodes(type, data);
        node = types.find((n) => n.id === id);
      }
    } else if (type === 'root') {
      node = data[0];
    }
    return node;
  }

  /**
   * Decode route params and find the node it is pointing to.
   */
  findNodeFromRoute(
    route: Params = Object.fromEntries(new URL(location.href).searchParams),
    data = this._currentAssetTree$.value as AssetNode[],
  ) {
    // Find lowest level id from route params
    let found = false;
    const { selected } = Object.entries(route).reduce(
      (acc: { level: number; selected: AssetNode }, [key, value]: string[]) => {
        // Some nodes can exist multiple places in the hierarchy.
        // Make sure we search for this node only amongst the children of the selected parent.
        data = acc.selected?.children as AssetNode[];
        const n = this.findNode(key as AssetNodeType, value, data);
        found = !!n;
        const l = n ? this._findLevel(n) : 0;
        return l > acc.level && n && this._isDescendant(n, acc.selected) ? { level: l, selected: n } : acc;
      },
      { level: 0, selected: data[0] as AssetNode },
    );
    return selected;
  }

  findClosest(type: string, node = this.getSelectedNode()): AssetNode | null {
    if (node?.type === type) {
      return node;
    }
    return node?.parent ? this.findClosest(type, node?.parent as AssetNode) : null;
  }

  private _isDescendant(node: AssetNode, parent: AssetNode): boolean {
    if (node.parent) {
      return node.parent === parent ? true : this._isDescendant(node.parent as AssetNode, parent);
    }
    return false;
  }

  private _findLevel(node: AssetNode, current = 0): number {
    if (node.parent) {
      current = this._findLevel(node.parent as AssetNode, current);
    }
    return current + 1;
  }

  /**
   * Traverse the asset tree and add the type for each level in the order they appear
   *
   * @param fromNodes the nodes to process
   * @param levels the set to add types to
   *
   * @returns a unique list of levels
   */
  collectLevels(fromNodes = this._currentAssetTree$.value as AssetNode[], levels = new Set<string>()): Set<string> {
    return fromNodes.reduce((l, n) => {
      l.add(n.type);
      if (n.children?.length) {
        this.collectLevels(n.children as AssetNode[], l);
      }
      return l;
    }, levels);
  }

  /**
   * The url representing the dashboard view for this node.
   * This is a recursive function which makes sure the entire
   * asset hierarchy is represented as query params in the url.
   */
  calcQueryParams(node: AssetNode): { [key: string]: string } {
    if (!node) {
      return {};
    }
    const url = this.calcQueryParams(node.parent as AssetNode) || {};
    if (node.id) {
      url[node.type] = node.id;
    }
    return url;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
