import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, HostBinding, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { AppService } from '@logic-suite/shared/app.service';
import { isElementInView, scrollIntoView, waitForElement } from '@logic-suite/shared/utils';
import { getEnv } from '@logic-suite/shared/utils/getEnv';

import { toSignal } from '@angular/core/rxjs-interop';
import { AssetTreeService } from './asset-tree.service';
import { AssetFunction, AssetNode, TreeNode } from './node.model';

@Component({
  selector: 'app-asset-tree',
  templateUrl: './asset-tree.component.html',
  styleUrls: ['./asset-tree.component.scss'],
  standalone: false,
})
export class AssetTreeComponent implements OnInit, OnChanges, OnDestroy {
  private assetTree = inject(AssetTreeService);
  protected route = inject(ActivatedRoute);
  private app = inject(AppService);

  @HostBinding('class') className = 'tree-container';
  /**
   * This allows users to modify the tree after data is loaded, and before it is
   * injected into the MatTreeNestedDataSource. It must return an array of TreeNode's.
   *
   * A TreeNode can either be an AssetNode, or a AssetFunction. The main distinction
   * between the two, is that an AssetFunction is always a leaf node, and has a
   * differently rendered style in order to suit the purpose of displaying functions
   * inside AssetNode's.This is of course also customizable.
   */
  @Input() treeMapper!: (nodes: TreeNode[]) => TreeNode[];
  @Input() unfiltered = false;
  @Input() combined = false;
  @Input() showTrail = getEnv('showHiddenStructureTrail', false);

  calcQueryParams = this.assetTree.calcQueryParams;
  isLoading = toSignal(this.assetTree.isLoading$);
  subscriptions: Subscription[] = [];

  private _triggerReload$ = new BehaviorSubject(0);
  treeLoaded$ = this._triggerReload$.pipe(
    tap(() => this.assetTree.isLoading$.next(true)),
    switchMap(() => this.app.application$),
    switchMap((appID) => this.assetTree.getAssetTree(this.combined ? 'Combined' : '' + appID)),
    tap(() => this.assetTree.isLoading$.next(false)),
    map((res) => (this.treeMapper ? this.treeMapper(res) : res)),
  );
  dataSource = new MatTreeNestedDataSource<TreeNode>();
  dataSource$ = new BehaviorSubject<TreeNode[]>([]);
  treeControl = new NestedTreeControl<TreeNode>((node: TreeNode) => node.children);
  isExpandable = (node: TreeNode) => node?.children?.length;

  isFunction = (_: number, node: TreeNode) => node && (node.isLeaf || !('type' in node));
  isAsset = (node: TreeNode) => node && 'type' in node;

  readonly isSelected = (node: AssetNode) => this.assetTree.isSelected(node);

  /**
   *
   */
  ngOnInit() {
    this.reloadData();

    this.subscriptions.push(this.treeLoaded$.subscribe((data) => this.dataSource$.next(data)));
    this.subscriptions.push(this.dataSource$.subscribe((data) => (this.dataSource.data = data)));

    // This will handle auto expansion of tree nodes, and auto selection based on query params.
    // The query params will change when the tree nodes routerlink is clicked, so do not include
    // a click handler on the nodes as this would make the app perform a selection twice.
    this.subscriptions.push(
      combineLatest([this.route.queryParams, this.dataSource$]).subscribe(([route, data]) => {
        if (data?.length) {
          this.treeControl.expand(data[0]);
          this.autoExpandBranch(route, data);
          const levels = Array.from(this.assetTree.collectLevels());
          if (!Object.keys(route).some((r) => levels.includes(r))) {
            // No node selected. Select root node
            this.selectNode(data[0]);
          }
        }
      }),
    );

    this.subscriptions.push(
      this.assetTree.nodeSelection$.subscribe((node) => {
        if (!node && this.dataSource.data?.length) {
          this.selectNode(this.dataSource.data[0]);
        }
      }),
    );
  }

  toggleNode($event: MouseEvent, node: TreeNode) {
    const treeNode = ($event.target as HTMLElement).closest('.mat-nested-tree-node');
    if (treeNode?.getAttribute('aria-expanded') === 'true') {
      // Animate node closing
      const sub = treeNode!.querySelector('.sub');
      sub!
        .animate({ translate: ['translateY(-42px) scaleY(0)'], opacity: [0], maxHeight: [0] }, { duration: 200 })
        .finished.then(() => {
          this.treeControl.collapse(node);
        });
    } else {
      this.treeControl.expand(node);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  hasHiddenChildren(node: TreeNode) {
    return node.children?.some((n) => n.hidden === true) ?? false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.type || changes.unfiltered) && !this.isLoading()) {
      this.reloadData();
    }
  }

  reloadData(): Observable<TreeNode[]> {
    this._triggerReload$.next(this._triggerReload$.value + 1);
    return this.treeLoaded$;
  }

  autoExpandBranch(route: Params, data?: TreeNode[]) {
    const selected = this.assetTree.findNodeFromRoute(route, data as AssetNode[]);

    // Expand from selected level
    const expand = (n: AssetNode) => {
      this.treeControl.expand(n);
      if (n.parent) {
        expand(n.parent as AssetNode);
      }
    };
    expand((selected.parent ? selected.parent : selected) as AssetNode);
    this.selectNode(selected);
  }

  /**
   * This should not be called manually. It is called from the `_autoExpand` function
   * during route resolve. Since every node in the tree is now a link, the router
   * takes care of selecting the appropriate node for us.
   *
   * @param node the currently selected asset management tree node
   */
  private selectNode(node: TreeNode): any {
    // Prevent selecting the same node.
    if (this.isAsset(node)) {
      const assetNode: AssetNode = node as AssetNode;
      if (!this.isSelected(assetNode)) {
        if (assetNode.hidden === true && !this.combined && (assetNode.children?.length ?? 0) > 0)
          return this.selectNode(assetNode.parent as TreeNode);
        this.assetTree.selectNode(assetNode);
      }
      // Scroll to element
      waitForElement('app-asset-tree .selected')
        .then((selectedEl) => {
          if (selectedEl && !isElementInView(selectedEl)) scrollIntoView(selectedEl);
        })
        .catch((ex) => {
          // Element not found. Do nothing.
        });
    }
  }

  /**
   * Only used for AssetFunctions
   */
  combineParams(node: TreeNode) {
    return Object.assign({}, this.calcQueryParams(node.parent as AssetNode), (node as AssetFunction).params);
  }
}
