import { Component } from '@angular/core';
import { AbstractTreeIconComponent } from './abstract-tree-icon.component';

@Component({
  selector: 'app-icon-meter-virtual',
  styleUrls: ['./icons.scss', './icons-tree.scss'],
  standalone: true,
  template: ` <svg viewBox="0 0 60 60" [attr.width]="size" [attr.height]="size" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="m 21.8,15.9 c -3.4,0 -6.2,2.8 -6.2,6.2 0,3.4 2.8,6.2 6.2,6.2 3.4,0 6.2,-2.8 6.2,-6.2 0,-3.4 -2.8,-6.2 -6.2,-6.2 z m 0,10.7 c -2.4,0 -4.4,-2 -4.4,-4.4 0,-2.4 2,-4.4 4.4,-4.4 2.4,0 4.4,2 4.4,4.4 0,2.4 -2,4.4 -4.4,4.4 z m -4.4,6.8 c -2.4,2.4 -2.4,6.4 0,8.8 1.2,1.2 2.8,1.8 4.4,1.8 1.6,0 3.2,-0.6 4.4,-1.8 2.4,-2.4 2.4,-6.4 0,-8.8 -2.4,-2.3 -6.5,-2.3 -8.8,0 z m 7.5,7.6 c -1.7,1.7 -4.5,1.7 -6.2,0 -1.7,-1.7 -1.7,-4.5 0,-6.2 0.8,-0.8 1.9,-1.3 3.1,-1.3 1.2,0 2.3,0.5 3.1,1.3 1.7,1.7 1.7,4.4 0,6.2 z m 8.9,-7.6 c -2.4,2.4 -2.4,6.4 0,8.8 1.2,1.2 2.8,1.8 4.4,1.8 1.6,0 3.2,-0.6 4.4,-1.8 2.4,-2.4 2.4,-6.4 0,-8.8 -2.4,-2.4 -6.4,-2.4 -8.8,0 z m 7.5,7.6 c -1.7,1.7 -4.5,1.7 -6.2,0 -1.7,-1.7 -1.7,-4.5 0,-6.2 0.9,-0.9 2,-1.3 3.1,-1.3 1.1,0 2.3,0.4 3.1,1.3 1.7,1.7 1.7,4.4 0,6.2 z M 38.2,28.4 c 1.6,0 3.2,-0.6 4.4,-1.8 2.4,-2.4 2.4,-6.4 0,-8.8 -2.4,-2.4 -6.4,-2.4 -8.8,0 -2.4,2.4 -2.4,6.4 0,8.8 1.2,1.2 2.8,1.8 4.4,1.8 z M 35.1,19 c 0.9,-0.9 2,-1.3 3.1,-1.3 1.1,0 2.3,0.4 3.1,1.3 1.7,1.7 1.7,4.5 0,6.2 -1.7,1.7 -4.5,1.7 -6.2,0 -1.7,-1.6 -1.7,-4.4 0,-6.2 z" />
      <path
        d="m 22.3,19.5 h -1 v 2.2 h -2.1 v 0.9 h 2.1 v 2.2 h 1 v -2.2 h 2.1 v -0.9 h -2.1 z m 1.2,16 -1.7,1.7 -1.7,-1.7 -0.7,0.6 1.7,1.7 -1.7,1.8 0.7,0.6 1.7,-1.7 1.7,1.7 0.7,-0.6 -1.8,-1.8 1.8,-1.7 z m 15.300001,4.200001 a 0.60000002,0.60000002 0 0 1 -0.6,0.6 0.60000002,0.60000002 0 0 1 -0.6,-0.6 0.60000002,0.60000002 0 0 1 0.6,-0.6 0.60000002,0.60000002 0 0 1 0.6,0.6 z m 0,-3.700001 a 0.60000002,0.60000002 0 0 1 -0.6,0.6 0.60000002,0.60000002 0 0 1 -0.6,-0.6 0.60000002,0.60000002 0 0 1 0.6,-0.6 0.60000002,0.60000002 0 0 1 0.6,0.6 z M 35.5,37.400002 h 5.3 v 0.9 h -5.3 z m 0,-15.700001 h 5.3 v 0.9 h -5.3 z" />
    </g>
  </svg>`,
})
export class IconMeterVirtualComponent extends AbstractTreeIconComponent {}
